/* Connexion.css */
.connexion-container {
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    height: 100vh;
    background-color: #f4f4f4;
}

.connexion-form {
    padding: 20px;
    margin-top: 5%;
    background: white;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.connexion-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-control {
    margin-bottom: 15px;
    width: 100%;
}

.form-control label {
    display: block;
    margin-bottom: 5px;
}

.form-control input {
    width: 100%;
    /* Assure que les inputs prennent toute la largeur du conteneur */
    padding: 10px;
    /* Ajustez selon vos préférences */
    margin: 5px 0;
    /* Espacement vertical entre les champs */
    -webkit-border-radius: 4px;

    border-radius: 4px;
    /* Bordures arrondies pour un look moderne */
    border: 1px solid #ddd;
    /* Bordure subtile */
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
    /* Inclut le padding et la bordure dans la largeur/hauteur de l'élément */
    font-size: 16px;
    /* Taille de texte uniforme */
}

.connexion-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.connexion-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .connexion-form {
        margin: 10px;
    }
}