.fiche-offre-emploi {
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
}

.fiche-offre-emploi .design-component-instance-node-2 {
  background-color: #170e91 !important;
  border-color: unset !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.fiche-offre-emploi .content-section {
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-justify-content: center;

  justify-content: center;
  padding-top: 80px;
  padding-right: 0px;
  position: relative;
  width: 1126px;
}

.fiche-offre-emploi p {
  margin: 0;
  font-size: 18px;
}

.fiche-offre-emploi .auto-layout-vertical {
  -webkit-align-items: center;

  align-items: center;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;

  position: relative;
  width: 100%;
}

.fiche-offre-emploi .accueil-nous {
  align-self: stretch;
  color: #655ec9;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
}

.fiche-offre-emploi .span {
  color: #655ec9;
  font-family: "Roboto", Helvetica;
  font-size: var(--body-small-font-size);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}

.fiche-offre-emploi .text-wrapper-5 {
  font-family: var(--domisimpli-desktop-bouton-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--domisimpli-desktop-bouton-font-style);
  font-weight: var(--domisimpli-desktop-bouton-font-weight);
  letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
  line-height: var(--domisimpli-desktop-bouton-line-height);
}

.fiche-offre-emploi .frame-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  margin-top: 32px;
}

.fiche-offre-emploi .text-wrapper-6 {
  color: #655ec9;
  font-family: var(--coode-NT-desktop-h4-font-family);
  font-size: var(--coode-NT-desktop-h4-font-size);
  font-style: var(--coode-NT-desktop-h4-font-style);
  font-weight: var(--coode-NT-desktop-h4-font-weight);
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing);
  line-height: var(--coode-NT-desktop-h4-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-offre-emploi .text-wrapper-7 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  position: relative;
  width: fit-content;
}

.fiche-offre-emploi .text-wrapper-8 {
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-offre-emploi .line {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.75px;
  position: relative;
  width: 100%;
}

.fiche-offre-emploi .auto-layout-vertical-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.fiche-offre-emploi .auto-layout-vertical-2 ul {
  margin-top: 0;
  margin-bottom: 0;
}

.fiche-offre-emploi .text-wrapper-9 {
  color: #170e91;
  font-family: "DM Sans", Helvetica;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  width: 612px;
}

.fiche-offre-emploi .offre-titres {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h2-font-family);
  font-size: var(--coode-NT-desktop-h2-font-size);
  font-style: var(--coode-NT-desktop-h2-font-style);
  font-weight: var(--coode-NT-desktop-h2-font-weight);
  letter-spacing: var(--coode-NT-desktop-h2-letter-spacing);
  line-height: var(--coode-NT-desktop-h2-line-height);
  padding: 24px 0 32px;
}

.fiche-offre-emploi .text-wrapper-10 {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
}

.fiche-offre-emploi .img {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
  margin: 56px 0;
}

.fiche-offre-emploi .text-wrapper-11 {
  align-self: stretch;
  color: #170e91;
  font-family: "DM Sans", Helvetica;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.fiche-offre-emploi .style-filled-2 {
  font-family: var(--coode-NT-desktop-body-bold-font-family) !important;
  font-size: var(--coode-NT-desktop-body-bold-font-size) !important;
  font-style: var(--coode-NT-desktop-body-bold-font-style) !important;
  font-weight: var(--coode-NT-desktop-body-bold-font-weight) !important;
  letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing) !important;
  line-height: var(--coode-NT-desktop-body-bold-line-height) !important;
}

.no-decoration {
  text-decoration: none;
}

.button-offre {
  -webkit-justify-content: center;

  justify-content: center;
  width: 100%;
  display: flex;
  padding-top: 56px;
  padding-bottom: 180px;
}

/* .margin-zero {
  margin: 0;
} */

.pointer-span {
  cursor: pointer;
}

@media (max-width: 391px) {
  .fiche-offre-emploi {
    overflow: hidden;
    padding: 48px 24px 0 24px;
  }

  .fiche-offre-emploi h2 {
    text-align: initial;
  }

  .fiche-offre-emploi .content-section {
    width: auto;
    /* gap: 32px; */
    padding-top: 0;
  }

  .fiche-offre-emploi .frame-2 {
    margin-bottom: 16px;
    margin-top: 40px;
    /* gap: 8px */
  }

  .fiche-offre-emploi .offre-titres {
    padding-top: 16px;
  }

  .fiche-offre-emploi .text-wrapper-9 {
    width: auto;
  }


  .fiche-offre-emploi .auto-layout-vertical {
    max-width: 94vw;
    padding: 0;
  }

  .fiche-offre-emploi .auto-layout-vertical-2 {
    width: auto;
    max-width: 94vw;
  }

  .fiche-offre-emploi .separation-line {
    margin: 32px auto;
  }

  /* .fiche-offre-emploi .margin-zero {
    margin: 0;
  } */

  .fiche-offre-emploi .text-wrapper-9 {
    line-height: 48px;
    text-align: center;
    font-size: 28px;
    margin: auto;
  }

  .fiche-offre-emploi .text-wrapper-11 {
    line-height: 48px;
    text-align: center;
    font-size: 28px;
  }

  .fiche-offre-emploi .span {
    color: #655ec9;
    font-family: var(--domisimpli-desktop-bouton-font-family);
    font-size: var(--coode-NT-desktop-body-small-font-size);
    font-style: var(--domisimpli-desktop-bouton-font-style);
    font-weight: var(--body-small-font-weight);
    letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
    line-height: var(--coode-NT-desktop-body-small-line-height);
  }

  .fiche-offre-emploi .text-wrapper-5 {
    font-family: var(--domisimpli-desktop-bouton-font-family);
    font-size: var(--coode-NT-desktop-body-small-font-size);
    font-style: var(--domisimpli-desktop-bouton-font-style);
    font-weight: var(--domisimpli-desktop-bouton-font-weight);
    letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
    line-height: var(--coode-NT-desktop-body-small-line-height);
  }

  .button-offre {
    padding-top: 48px;
    padding-bottom: 180px;
  }

}