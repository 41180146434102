.scroll-to-top {
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: var(--coode-NT-blue);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
  -webkit-border-radius: 24%;

  border-radius: 24%;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.3s ease, color 0.3s ease;

  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Changement de couleur au survol du bouton */
.scroll-to-top:hover .scroll-arrow,
.scroll-to-top:active .scroll-arrow {
  color: var(--coode-NT-blue);
  /* Bleu au survol et au clic */
}

/* Pour que le bouton change aussi de couleur si nécessaire */
.scroll-to-top:hover {
  background-color: var(--coode-NT-blanc);
}

.scroll-to-top:active {
  background-color: var(--coode-NT-blue);
}