.fiche-nous-rejoindre {
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
}

.fiche-nous-rejoindre p {
  margin: 0;
}

.fiche-nous-rejoindre .header-1 {
  align-self: stretch !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
  width: 100% !important;
}

.fiche-nous-rejoindre .design-component-instance-node-2 {
  background-color: #170e91 !important;
  border-color: unset !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.fiche-nous-rejoindre .content-section {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 24px;
  -webkit-justify-content: center;

  justify-content: center;
  padding: 80px 0px 180px;
  position: relative;
  width: 1126px;
}

.fiche-nous-rejoindre .auto-layout-vertical {
  -webkit-align-items: center;

  align-items: center;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
  width: 100%;
}

.fiche-nous-rejoindre .accueil-d-veloppeur {
  align-self: stretch;
  color: #655ec9;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.fiche-nous-rejoindre .span {
  color: #655ec9;
  font-family: "Roboto", Helvetica;
  font-size: var(--body-small-font-size);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}

.fiche-nous-rejoindre .text-wrapper-5 {
  font-family: var(--domisimpli-desktop-bouton-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--domisimpli-desktop-bouton-font-style);
  font-weight: var(--domisimpli-desktop-bouton-font-weight);
  letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
  line-height: var(--domisimpli-desktop-bouton-line-height);
}

.fiche-nous-rejoindre .frame-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  margin-top: 32px;
}

.fiche-nous-rejoindre .text-wrapper-6 {
  color: #655ec9;
  font-family: var(--coode-NT-desktop-h4-font-family);
  font-size: var(--coode-NT-desktop-h4-font-size);
  font-style: var(--coode-NT-desktop-h4-font-style);
  font-weight: var(--coode-NT-desktop-h4-font-weight);
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing);
  line-height: var(--coode-NT-desktop-h4-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .text-wrapper-7 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  position: relative;
  width: fit-content;
}

.fiche-nous-rejoindre .text-wrapper-8 {
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .line {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.75px;
  position: relative;
  width: 100%;
}

.fiche-nous-rejoindre .frame-3 {
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  border: 1px solid;
  border-color: #655EC9;
  -webkit-border-radius: 16px;

  border-radius: 16px;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;

  padding: 40px;
  position: relative;
  width: 553px;
}

.fiche-nous-rejoindre input[type="text"],
.fiche-nous-rejoindre input[type="email"],
.fiche-nous-rejoindre input[type="tel"],
.fiche-nous-rejoindre textarea,
.fiche-nous-rejoindre select {
  width: 100%;
  max-width: 100%;
  resize: vertical;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
  -webkit-box-sizing: border-box;

  box-sizing: border-box;
  margin: 0;
}

.fiche-nous-rejoindre input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.fiche-nous-rejoindre form {
  padding: 0;
  margin: 0;
  width: 100%;
}

.fiche-nous-rejoindre .frame-3 .frame-4:not(:first-child) {
  margin-top: 32px;
}

.fiche-nous-rejoindre .frame-4 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.fiche-nous-rejoindre .text-wrapper-9 {
  color: #000000;
  font-family: var(--coode-NT-desktop-body-bold-font-family);
  font-size: var(--coode-NT-desktop-body-bold-font-size);
  font-style: var(--coode-NT-desktop-body-bold-font-style);
  font-weight: var(--coode-NT-desktop-body-bold-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing);
  line-height: var(--coode-NT-desktop-body-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre textarea {
  resize: vertical;
}


.fiche-nous-rejoindre .selected-shape {
  border: 3px solid;
  border-color: var(--black-20);
  -webkit-border-radius: 4px;

  border-radius: 4px;
  height: 54px;
  left: -3px;
  position: absolute;
  top: -3px;
  width: 479px;
}

.fiche-nous-rejoindre .field {
  -webkit-align-items: center;

  align-items: center;
  -webkit-border-radius: 4px;

  border-radius: 4px;
  display: flex;
  height: 48px;
  left: 0;
  overflow: hidden;
  padding: 13px 16px;
  position: absolute;
  top: 0;
  width: 473px;
}

.fiche-nous-rejoindre .text-wrapper-10 {
  color: var(--black);
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .indicator-right {
  background-color: var(--black);
  -webkit-border-radius: 1px;

  border-radius: 1px;
  height: 22px;
  position: relative;
  width: 1px;
}

.fiche-nous-rejoindre .spacer {
  -webkit-border-radius: 1px;

  border-radius: 1px;
  -webkit-flex: 1;

  flex: 1;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  height: 22px;
  position: relative;
}

.fiche-nous-rejoindre .field-2 {
  -webkit-align-items: center;

  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--black-20);
  -webkit-border-radius: 4px;

  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 13px 16px;
  position: relative;
  width: 100%;
}

.fiche-nous-rejoindre .text-wrapper-11 {
  color: var(--black-60);
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .exemple-gmail-com {
  background: transparent;
  border: none;
  color: var(--black-60);
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  margin-top: -0.5px;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .field-3 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--black-20);
  -webkit-border-radius: 4px;

  border-radius: 4px;
  display: flex;
  height: 168px;
  overflow: hidden;
  padding: 13px 16px;
  position: relative;
  width: 100%;
}

.fiche-nous-rejoindre .text-wrapper-12 {
  color: var(--black-60);
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fiche-nous-rejoindre .field-4 {
  -webkit-align-items: center;

  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #111112;
  -webkit-border-radius: 16px;

  border-radius: 16px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0px 10px;
  position: relative;
  width: 100%;
  max-width: 100%;
  -webkit-box-sizing: border-box;

  box-sizing: border-box;
}

.fiche-nous-rejoindre .file-placeholder {
  color: #636363;
}

.fiche-nous-rejoindre .style-outlined-2 {
  border-color: #170e91 !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.fiche-nous-rejoindre .style-outlined-3 {
  color: #170e91 !important;
  font-family: var(--coode-NT-desktop-body-font-family) !important;
  font-size: var(--coode-NT-desktop-body-font-size) !important;
  font-style: var(--coode-NT-desktop-body-font-style) !important;
  font-weight: var(--coode-NT-desktop-body-font-weight) !important;
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing) !important;
  line-height: var(--coode-NT-desktop-body-line-height) !important;
}

.fiche-nous-rejoindre .style-filled-2 {
  font-family: var(--coode-NT-desktop-body-bold-font-family) !important;
  font-size: var(--coode-NT-desktop-body-bold-font-size) !important;
  font-style: var(--coode-NT-desktop-body-bold-font-style) !important;
  font-weight: var(--coode-NT-desktop-body-bold-font-weight) !important;
  letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing) !important;
  line-height: var(--coode-NT-desktop-body-bold-line-height) !important;
}

.fiche-nous-rejoindre .frame-34 {
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.no-decoration {
  text-decoration: none;
}

.fiche-nous-rejoindre .button {
  all: unset;
  -webkit-align-items: center;

  align-items: center;
  border: 1px solid;
  border-color: var(--neutral-10);
  -webkit-border-radius: 50px;

  border-radius: 50px;
  -webkit-box-sizing: border-box;

  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  -webkit-justify-content: center;

  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
}

.fiche-nous-rejoindre .button .do-something {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

/* .button.filled {
  background-color: var(--neutral-10);
}

.button.filled .do-something {
  color: var(--neutral-0);
}

.button.outlined .do-something {
  color: var(--neutral-10);
} */

.fiche-nous-rejoindre .submit-button {
  display: flex;
  width: 100%;
  margin-top: 32px;
  -webkit-justify-content: center;

  justify-content: center;
}

.fiche-nous-rejoindre .error-message {
  color: red;
  font-size: 14px;
  font-weight: 700;
  margin-left: 22px;
  margin-top: -14px;
}

@media only screen and (max-width: 768px) {

  .fiche-nous-rejoindre .content-section {
    padding: 40px 0px 180px;
    max-width: 94vw;
    gap: 0px;
  }

  .fiche-nous-rejoindre .auto-layout-vertical {
    gap: 0px;
  }

  .fiche-nous-rejoindre .field {
    width: auto;
  }

  .fiche-nous-rejoindre .selected-shape {
    width: auto;
  }

  .fiche-nous-rejoindre .frame-3 {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    align-self: stretch;
    width: auto;
    padding: 32px;
  }

  .fiche-nous-rejoindre .text-wrapper-7 {
    align-self: self-start;
  }

  .fiche-nous-rejoindre .accueil-d-veloppeur {
    font-size: var(--font-size-xxxs, 12px);
  }

  .fiche-nous-rejoindre .text-wrapper-9 {
    text-align: start;
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-style: var(--coode-NT-mobile-body-font-style);
    font-weight: var(--coode-NT-mobile-body-font-weight);
  }

  .fiche-nous-rejoindre .text-wrapper-11 {
    line-height: 140%;
    text-align: center;
    font-size: 16px;
  }

  .fiche-nous-rejoindre .field-4 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
  }

  .fiche-nous-rejoindre input {
    align-self: stretch;
    height: 22px;
    position: relative;
    max-width: 100%;
    margin: auto;
    padding: 10px;
  }

  .fiche-nous-rejoindre textarea {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    min-height: 168px;
    resize: vertical;
    margin: auto;
  }

  .frame-4 input[type="text"],
  .frame-4 input[type="email"],
  .frame-4 input[type="tel"],
  .frame-4 textarea,
  .frame-4 select,
  .frame-4 input[type="file"] {
    max-width: 100%;
    width: 100%;
    height: auto;
  }


  .frame-4 input:focus,
  .frame-4 textarea:focus {
    border-color: var(--coode-NT-violet-clair);
    /* Changez cette couleur selon vos préférences */
    outline: none;
    /* Supprime l'outline par défaut */
    /*box-shadow: 0 0 0  var(--coode-NT-violet-clair); /* Ajoute un effet de focus subtil */
  }


  .fiche-nous-rejoindre .span {
    color: #655ec9;
    font-family: var(--domisimpli-desktop-bouton-font-family);
    font-size: var(--coode-NT-desktop-body-small-font-size);
    font-style: var(--domisimpli-desktop-bouton-font-style);
    font-weight: var(--body-small-font-weight);
    letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
    line-height: var(--coode-NT-desktop-body-small-line-height);
  }

  .fiche-nous-rejoindre .text-wrapper-5 {
    font-family: var(--domisimpli-desktop-bouton-font-family);
    font-size: var(--coode-NT-desktop-body-small-font-size);
    font-style: var(--domisimpli-desktop-bouton-font-style);
    font-weight: var(--domisimpli-desktop-bouton-font-weight);
    letter-spacing: var(--domisimpli-desktop-bouton-letter-spacing);
    line-height: var(--coode-NT-desktop-body-small-line-height);
  }


  .fiche-nous-rejoindre .frame-2 {
    gap: 8px;
    margin-top: 32px;
  }

  .fiche-nous-rejoindre .style-outlined-3 {
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--body-small-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
  }

}