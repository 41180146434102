.answer-candidate {
    -webkit-border-radius: 5px;

    border-radius: 5px;
    padding: 24px;
}

.answer-candidate .answer-editor {
 min-height: 250px;
}

.answer-candidate div.ql-editor {
 min-height: 250px;
}

.answer-candidate .margin {
 margin-top: 24px;
}