.custom-h2 {
    color: #170e91;
}

.custom-h3 {
    color: #170e91;
}


.editor-wrapper {
    width: 100%;
    background: #fff;
    padding: 1rem;
}

.editor-container {
    width: 100%;
    border: 1px solid #170e91;
    padding: 1rem;
}

.toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-basis: 1fr 1fr;

    flex-basis: 1fr 1fr;
}

button {
    margin-right: 8px;
    font-size: 1rem;
    padding: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
}

.superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

.codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: #ffeff0;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    -webkit-box-decoration-break: slice;

    box-decoration-break: slice;
    padding: 0.3rem 2rem;
    -webkit-border-radius: 0.2rem;

    border-radius: 0.2rem;
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.justifyAlign {
    text-align: justify;
}

ul,
ol {
    margin-left: 1.5rem;
    /* Espace à gauche pour l'indentation */
}

ul li,
ol li {
    margin-bottom: 0.5rem;
    /* Espace entre les éléments de la liste */
}

ul {
    list-style-type: disc;
    /* Style des puces pour les listes non ordonnées */
}

ol {
    list-style-type: decimal;
    /* Style des numéros pour les listes ordonnées */
}

.export-html {
    background: #170e91;
    color: #fff;
    padding: 10px;
    border: none;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
}

.export-html:hover {
    background: #0c221d;
}

.suppresion-article {
    background: #a00000;
    color: #fff;
    padding: 10px;
    border: none;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
}

.suppresion-article:hover {
    background: #660000;
}

.article-editor-button {
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    margin-top: 20px;
}