.job-offers-list {
    width: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
    overflow: hidden;

}

.job-offers-list * {
    max-width: 100%;
    max-height: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
}

.job-offers-list .offers-editor {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 20px;
    width: 60vw;
    margin: 0 auto;
}

.job-offers-list .imported-image {
    max-width: 250px;
}


.job-offers-list .offers-editor {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    border: 1px solid;
    border-color: #655ec9;
    -webkit-border-radius: 16px;

    border-radius: 16px;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 32px;
    padding: 40px;
    position: relative;
    width: auto;
    margin: 24px;
}

.offers-editor .frame-4:not(:first-child) {
    margin-top: 32px;
}

.job-offers-list .frame-4 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
}

.job-offers-list .offers-editor {
    -webkit-flex: 0 0 auto !important;

    flex: 0 0 auto !important;
}

.job-offers-list .submit-button {
    display: flex;
    width: 100%;
    margin-top: 32px;
    -webkit-justify-content: center;

    justify-content: center;
}

.job-offers-list .pointer-span {
    cursor: pointer;
}

.job-offers-list .button-3 {
    border-color: unset !important;
    -webkit-flex: 0 0 auto !important;

    flex: 0 0 auto !important;
}

.job-offers-list table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    background-color: #f0f0f5;
}


.job-offers-list th {
    text-align: center;
    color: black;
    font-size: 20px;
    border-bottom: 5px solid #f0f0f5;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 10px;
}

.job-offers-list td {
    text-align: center;
    font-weight: 600;
    color: #000;
    vertical-align: middle;
    padding: 10px;
}

.job-offers-list td.long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}

.job-offers-list td.candidature-count {
    text-align: center;
}

.job-offers-list .del-button {
   color: red;
}

.job-offers-list .candidature-count-number {
    display: inline-block;
    padding: 8px 12px;
    background-color: red;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    -webkit-border-radius: 100%;

    border-radius: 100%;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.1);

    box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.3s ease;

    transition: background-color 0.3s ease;
}

.job-offers-list .candidature-count-number:hover {
    background-color: rgb(150, 0, 0);
}

.job-offers-list .candidature-count-number:active {
    background-color: #550303;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(2px);

    transform: translateY(2px);
    -webkit-transform: scale(0.98);

    transform: scale(0.98);
}

.job-offers-list .button {
    padding: 6px 12px;
    border: none;
    background-color: #170e91;
    color: white;
    cursor: pointer;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    -webkit-transition: background-color 0.3s;

    transition: background-color 0.3s;
}

.job-offers-list .button:hover {
    background-color: #170e91;
}

.job-offers-list .button:focus {
    outline: none;
}

.job-offers-list .button-td {
    text-align: center;
}

.job-offers-list .error-text {
    color: red;
    font-size: 14px;
    font-weight: 700;
}

.job-offers-list .add-offer-button {
    display: flex;
    margin: 24px;
}

.job-offers-list .add-offer-button button {
margin: 0 8px;
}

.job-offers-list .add-offer-button .new {
    background-color: #2f25f0;
}

.job-offers-list .add-offer-button .undo {
    background-color: #a8a8a8;
}

.job-offers-list .add-offer-button .new:hover {
    background-color: #2820d1;
}

.job-offers-list .add-offer-button .undo:hover {
    background-color: #6d6d6d;
}


.job-offers-list .button-form {
    width: 100%;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    display: flex;
}

.job-offers-list .tabs {
    display: flex;
    -webkit-justify-content: space-around;

    justify-content: space-around;
    margin-bottom: 20px;
}

.job-offers-list .tab {
    cursor: pointer;
    padding: 10px 20px;
    background-color: lightgray;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    -webkit-transition: background-color 0.3s;

    transition: background-color 0.3s;
}

.job-offers-list .tab.active {
    background-color: #007BFF;
    color: white;
}

.job-offers-list .text-wrapper-9 {
    color: var(--coode-NT-blue);
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
}

.job-offers-list .editor-tabs {
    color: var(--coode-NT-blue);
    display: flex;
    gap: 16px;
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.job-offers-list .editor-tab {
    cursor: pointer;
    padding: 8px 2px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

.job-offers-list .editor-tab:hover {
    background-color: #dad7d7;
}

.job-offers-list .active {
    background-color: #88abf8;
}

.job-offers-list .answer-editor {
    width: -webkit-fill-available;
}