.candidat-list {
    width: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
    overflow: visible;

}

.candidat-list * {
    max-width: 100%;
    max-height: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
}

.candidat-list .collapse-content {
    text-align: left;
    padding: 8px;
}

.candidat-list .candidat-answer-button {
    display: flex;
    -webkit-justify-content: space-around;

    justify-content: space-around;
}

.candidat-list .clickable-row.active td {
    background: #a8a7a7;

}


.candidat-list table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    background-color: #f0f0f5;
}

.candidat-list tr {
    -webkit-border-radius: 8px;

    border-radius: 8px;
    border: 8px solid;
    border-color: #FFF;
}

.candidat-list th {
    text-align: center;
    color: black;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 10px;
}

.candidat-list td {
    text-align: center;
    vertical-align: middle;
    padding: 10px;
}

.candidat-list td.long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}

.candidat-list td.candidature-count {
    text-align: center;
}