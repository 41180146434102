.header-1 {
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
}

.header {
  -webkit-align-items: center;

  align-items: center;
  background-color: var(--coode-NT-blanc);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--coode-NT-violet-clair);
  display: flex;
  -webkit-justify-content: space-between;

  justify-content: space-between;
  padding: 24px 98px;
  position: relative;
  z-index: 10;
}

.header .coode-nt-full {
  height: 38px;
  object-fit: cover;
  width: 153.41px;
}

/* Masquer le menu burger par défaut sur desktop */
.burger-icon {
  display: none;
}

/* Le menu est caché par défaut sur mobile */
.header .auto-layout {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: row;

  flex-direction: row;
  gap: 48px;
  position: relative;
  max-height: none;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: none;

  transition: none;
}

/* Empile les éléments et les affiche si le menu est ouvert */
.auto-layout.open {
  max-height: 500px;
  /* Définissez une hauteur suffisante pour l'affichage complet du menu */
  opacity: 1;
  padding-top: 32px;
  border-bottom-width: 1px;
}

.header .auto-layout {
  -webkit-align-items: center;

  align-items: center;
  display: inline-flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 48px;
  position: relative;
}

.header .text-wrapper,
.header .div {
  color: var(--coode-NT-blue);
  display: block;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.auto-layout a {
  text-decoration: none;
}

.header .actif {
  font-weight: bold;
}

.header .button-instance {
  font-weight: 700 !important;
  /* Le texte de l'ActionButton sera en gras */
}

/* Media Queries pour mobile */
@media only screen and (max-width: 768px) {
  .header {
    padding: 16px;
    max-width: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
  }

  .header .coode-nt-full {
    height: 32px;
    width: auto;
  }

  .header .auto-layout {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--coode-NT-blanc);
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 0;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: max-height 0.6s ease;

    transition: max-height 0.6s ease;
    z-index: 10;
  }

  .header .auto-layout.open {
    max-height: 400px;
    opacity: 1;
    padding: 0 24px 16px 24px;
    max-width: 342px;
    border-color: var(--coode-NT-violet-clair);
    border-bottom-style: solid;
  }

  .burger-icon {
    display: block;
    cursor: pointer;
    font-size: 24px;
    color: var(--coode-NT-blue);
  }

  .header .text-wrapper,
  .header .div,
  .header button {
    font-size: var(--coode-NT-mobile-body-font-size);
    margin-top: 56px;
  }

  .header .button-instance {
    font-size: var(--coode-NT-mobile-body-font-size) !important;
  }

  .menu-btn {
    position: relative;
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    -webkit-align-items: center;

    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;

    transition: all 0.5s ease-in-out;

  }

  .menu-btn__burger {
    width: 30px;
    height: 4px;
    background: var(--coode-NT-blue);
    -webkit-border-radius: 5px;

    border-radius: 5px;
    -webkit-transition: all 0.5s ease-in-out;

    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    background: var(--coode-NT-blue);
    -webkit-border-radius: 5px;

    border-radius: 5px;
    -webkit-transition: all 0.5s ease-in-out;

    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger::before {
    -webkit-transform: translateY(-10px);

    transform: translateY(-10px);
  }

  .menu-btn__burger::after {
    -webkit-transform: translateY(10px);

    transform: translateY(10px);
  }

  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    -webkit-transform: translateX(-25px);

    transform: translateX(-25px);
    background: transparent;
    -webkit-box-shadow: none;

    box-shadow: none;
  }

  .menu-btn.open .menu-btn__burger::before {
    -webkit-transform: rotate(45deg) translate(15px, -15px);

    transform: rotate(45deg) translate(15px, -15px);
  }

  .menu-btn.open .menu-btn__burger::after {
    -webkit-transform: rotate(-45deg) translate(15px, 15px);

    transform: rotate(-45deg) translate(15px, 15px);
  }
}