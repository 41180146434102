.accueil {
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
  padding: 0 16px;
}

.accueil h1 {
  margin: 0;
}

.accueil .design-component-instance-node-2 {
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.accueil .header-1 {
  background-color: #170e91 !important;
  border-color: unset !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.accueil .auto-layout-2 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: row;

  flex-direction: row;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 40px;
  padding: 120px 0 80px 0;
  position: relative;
  width: 90vw;
  max-width: 1124px;
}

.accueil .div-5 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  display: flex;
  -webkit-flex: 1;

  flex: 1;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.accueil .auto-layout-vertical {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
  width: 100%;
  padding-bottom: 16px;
}

.accueil .text-wrapper-5 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  position: relative;
}

.accueil p {
  margin: 0;
}

.accueil .p {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
}

.accueil .placeholder-image {
  max-width: 100%;
  height: auto;
  padding-top: 8px;
  object-fit: contain; /* Adapte l'image au conteneur sans déformation */
  flex-shrink: 0; /* Empêche la compression de l'image */
}

.accueil .accueil_1 {
  width: 478px;
}

@keyframes backgroundColorChange {
  0% {
    background-color: #dedcf0;
  }
  100% {
    background-color: #d3d1eb;
  }
}

@keyframes fadeBorder {
  0%,
  100% {
    border-color: transparent; /* Bordure invisible au début et à la fin */
    opacity: 0; /* Rendre transparent */
  }
  50% {
    border-color: #170e91; /* Couleur visible à mi-chemin */
    opacity: 1; /* Bordure complètement visible */
  }
}

.accueil .bloc-evaluate {
  align-items: center;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1124px;
  height: auto;
  background-color: #dedcf0;
  padding: 16px;
  margin: auto;
  margin-top: 8px;

  cursor: pointer;
  animation: backgroundColorChange 4s ease-in-out infinite alternate;
  border: 2px solid transparent; /* Bordure initiale invisible */
}

.accueil .bloc-evaluate::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 2px solid #170e91; /* Couleur et épaisseur de la bordure */
  animation: fadeBorder 1500ms ease-in-out infinite; /* Animation continue */
}

.accueil .bloc-evaluate p {
  margin: auto;
  text-align: center;
}

.accueil .content-section {
  padding: 80px 0;
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
  width: 90vw;
  max-width: 1126px;
}

.accueil .title {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.accueil .text-wrapper-6 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h2-font-family);
  font-size: var(--coode-NT-desktop-h2-font-size);
  font-style: var(--coode-NT-desktop-h2-font-style);
  font-weight: var(--coode-NT-desktop-h2-font-weight);
  letter-spacing: var(--coode-NT-desktop-h2-letter-spacing);
  line-height: var(--coode-NT-desktop-h2-line-height);
  padding-bottom: 24px;
  position: relative;
}

.accueil .text-wrapper-7 {
  margin: 0;
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-bold-font-family);
  font-size: var(--coode-NT-desktop-body-bold-font-size);
  font-style: var(--coode-NT-desktop-body-bold-font-style);
  font-weight: var(--coode-NT-desktop-body-bold-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing);
  line-height: var(--coode-NT-desktop-body-bold-line-height);
  position: relative;
  padding-bottom: 56px;
}

.accueil .content {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 56px;
}

.accueil .la-fois-pour-nos {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  margin-top: -1px;
  position: relative;
}

.accueil .social-proof {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 120px;
  -webkit-justify-content: space-around;

  justify-content: space-around;
  padding: 80px 0 180px 0;
  position: relative;
  width: 90vw;
  max-width: 1124px;
}

.accueil .auto-layout-3 {
  -webkit-align-items: stretch;

  align-items: stretch;
  display: flex;
  -webkit-flex: 1;

  flex: 1;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.accueil .auto-layout-vertical-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  background-color: #655ec91a;
  -webkit-border-radius: 24px;

  border-radius: 24px;
  display: flex;
  -webkit-flex: 1;

  flex: 1;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  gap: 16px;
  padding: 32px;
  position: relative;
}

.accueil .text-wrapper-8 {
  align-self: stretch;
  color: var(--neutral-7);
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
}

.accueil .element {
  color: transparent;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.accueil .span {
  color: #25e89f;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
}

.accueil .text-wrapper-9 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
}

@media only screen and (max-width: 430px) {
  .accueil .bloc-evaluate {
    width: 90%;
    margin: 0 24px 8px;
  }
}
@media only screen and (max-width: 768px) {
  .accueil {
    overflow: hidden;
    margin: 48px 0 180px 0;
  }

  .accueil .content-section {
    gap: 24px;
    margin-top: 48px;
    padding: 0;
  }

  .accueil .auto-layout-2,
  .accueil .social-proof {
    width: 100%;
    padding-block: 48px;
    overflow: hidden;
  }

  .accueil .auto-layout-2 {
    padding-top: 0;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 32px;
  }

  .accueil .content {
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 0;
  }

  .accueil .div-5 {
    gap: 0;
  }

  .accueil .auto-layout-vertical {
    padding-bottom: 16px;
  }

  .accueil .auto-layout-vertical-2 {
    padding: 24px;
  }

  .accueil .social-proof {
    gap: 60px;
    padding-top: 48px;
    padding-bottom: 0;
  }

  .accueil .auto-layout-3 {
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 24px;
  }

  .accueil .text-wrapper-5,
  .accueil .element,
  .accueil .span,
  .accueil .text-wrapper-9 {
    font-family: var(--coode-NT-mobile-h1-font-family);
    font-size: var(--coode-NT-mobile-h1-font-size);
    font-style: var(--coode-NT-mobile-h1-font-style);

    letter-spacing: var(--coode-NT-mobile-h1-letter-spacing);
    line-height: var(--coode-NT-mobile-h1-line-height);
  }

  .accueil .text-wrapper-6 {
    font-family: var(--coode-NT-mobile-h2-font-family);
    font-size: var(--coode-NT-mobile-h2-font-size);
    font-style: var(--coode-NT-mobile-h2-font-style);
    font-weight: var(--coode-NT-mobile-h2-font-weight);
    letter-spacing: var(--coode-NT-mobile-h2-letter-spacing);
    line-height: var(--coode-NT-mobile-h2-line-height);
    padding: 0;
  }

  .accueil .text-wrapper-7 {
    font-family: var(--coode-NT-mobile-body-bold-font-family);
    font-size: var(--coode-NT-mobile-body-bold-font-size);
    font-style: var(--coode-NT-mobile-body-bold-font-style);
    font-weight: var(--coode-NT-mobile-body-bold-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-bold-letter-spacing);
    line-height: var(--coode-NT-mobile-body-bold-line-height);
    padding: 0;
  }

  .accueil .p,
  .accueil .text-wrapper-8 {
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-style: var(--coode-NT-mobile-body-font-style);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

  .accueil .p {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .style-outlined-wrapper-contact {
    margin-bottom: 48px;
  }

  .accueil .content {
    padding-bottom: 8px;
  }
}
