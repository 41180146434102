.nous-rejoindre {
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
}

.nous-rejoindre .header-1 {
  align-self: stretch !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
  width: 100% !important;
}

.nous-rejoindre .header-instance {
  background-color: #170e91 !important;
  border-color: unset !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.nous-rejoindre .content-section {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 56px;
  -webkit-justify-content: center;

  justify-content: center;
  padding: 120px 0px 180px 0;
  position: relative;
  max-width: 1126px;
}

.nous-rejoindre .auto-layout-vertical {
  -webkit-align-items: center;

  align-items: center;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.nous-rejoindre .auto-layout-vertical-2 {
  align-self: flex-start;
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 32px;
  -webkit-justify-content: center;

  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1770px;
}

.nous-rejoindre .text-wrapper-5 {
  align-self: flex-start;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.nous-rejoindre .text-wrapper-6 {
  text-align: start;
  align-self: flex-start;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
}
.nous-rejoindre .no-offers {
  text-align: center;
  align-self: flex-start;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
}

.nous-rejoindre .line {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.nous-rejoindre .auto-layout-vertical-3 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  border: 1px solid;
  border-color: #655ec9;
  -webkit-border-radius: 16px;

  border-radius: 16px;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  height: 660px;
  -webkit-justify-content: space-between;

  justify-content: space-between;
  padding: 24px;
  position: relative;
  width: 362px;
}

.nous-rejoindre .auto-layout-vertical-4 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  /* gap: 16px; */
  position: relative;
  width: 100%;
}

.nous-rejoindre .text-wrapper-7 {
  align-self: stretch;
  color: #655ec9;
  font-family: var(--coode-NT-desktop-h4-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-h4-font-style);
  font-weight: var(--coode-NT-mobile-body-bold-font-weight);
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing);
  line-height: var(--coode-NT-desktop-h4-line-height);
  margin-top: -1px;
  position: relative;
  margin-bottom: 16px;
}

.nous-rejoindre .text-wrapper-8 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h3-font-family);
  font-size: var(--coode-NT-desktop-h3-font-size);
  font-style: var(--coode-NT-desktop-h3-font-style);
  font-weight: var(--coode-NT-desktop-h3-font-weight);
  letter-spacing: var(--coode-NT-desktop-h3-letter-spacing);
  line-height: var(--coode-NT-desktop-h3-line-height);
  position: relative;
  margin-bottom: 16px;
}

.nous-rejoindre .frame-3 {
  -webkit-align-items: center;

  align-items: center;
  display: inline-flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.nous-rejoindre .mdi-location {
  height: 24px;
  position: relative;
  color: #170e91;
  width: 24px;
}

.nous-rejoindre .p {
  color: #170e91;
  font-family: var(--coode-NT-desktop-body-bold-font-family);
  font-size: var(--coode-NT-desktop-body-bold-font-size);
  font-style: var(--coode-NT-desktop-body-bold-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing);
  line-height: var(--coode-NT-desktop-body-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: 0;
}

.nous-rejoindre .text-wrapper-9 {
  overflow: hidden;
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  margin: 0;
}

.nous-rejoindre .auto-layout-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  display: inline-flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.nous-rejoindre .design-component-instance-node-2 {
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.job-offers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.nous-rejoindre .no-grid {
  grid-template-columns: auto;
}

.pointer-span {
  cursor: pointer;
}

@media (max-width: 768px) {
  .job-offers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .job-offers-grid {
    grid-template-columns: 1fr;
  }

  .nous-rejoindre .text-wrapper-6 {
    max-width: 400px;
  }

  .nous-rejoindre .auto-layout-vertical-2 {
    padding-left: 24px;
  }

  .nous-rejoindre .job-offers-grid {
    padding-left: 10px;
  }
}

@media (max-width: 375px) {
  .nous-rejoindre {
    overflow: hidden;
  }

  .nous-rejoindre .content-section {
    width: 100%;
    padding: 48px 0px 180px 0;
    gap: 32px;
  }

  .nous-rejoindre .separation-line {
    margin: 32px 0;
  }

  .nous-rejoindre .auto-layout-vertical {
    gap: 0px;
  }

  .nous-rejoindre .job-offers-grid {
    display: flex;
    -webkit-flex-direction: row;

    flex-direction: row;
    max-width: 100%;
    margin-left: 24px;
    overflow-x: auto;
    gap: 16px;
  }

  .nous-rejoindre .job-offers-grid > *:last-child {
    margin-right: 24px;
  }

  .job-offers-grid::-webkit-scrollbar {
    display: none;
  }

  .nous-rejoindre .auto-layout-vertical-3 {
    width: 282px;
    height: 518px;
  }

  .nous-rejoindre .text-wrapper-6 {
    max-width: 342px;
  }
}
