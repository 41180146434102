.rejoindre-admin {
    width: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
}

.rejoindre-admin * {
    max-width: 100%;
    max-height: 100%;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
}

.rejoindre-admin .offers-editor {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 20px;
    width: 60vw;
    margin: 0 auto;
}

.rejoindre-admin .imported-image {
    max-width: 250px;
}

.rejoindre-admin input[type="text"],
.rejoindre-admin input[type="email"],
.rejoindre-admin input[type="tel"],
.rejoindre-admin textarea,
.rejoindre-admin select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
}

.rejoindre-admin input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
} 

.rejoindre-admin .offers-editor {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    border: 1px solid;
    border-color: #655ec9;
    -webkit-border-radius: 16px;

    border-radius: 16px;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 32px;
    padding: 40px;
    position: relative;
    width: auto;
    margin: 24px;
}

.offers-editor .frame-4:not(:first-child) {
    margin-top: 32px;
}

.rejoindre-admin .frame-4 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
}

.rejoindre-admin .offers-editor {
    -webkit-flex: 0 0 auto !important;

    flex: 0 0 auto !important;
}

.rejoindre-admin .submit-button {
    display: flex;
    width: 100%;
    margin-top: 32px;
    -webkit-justify-content: center;

    justify-content: center;
}

.rejoindre-admin .pointer-span {
    cursor: pointer;
}

.rejoindre-admin .button-3 {
    border-color: unset !important;
    -webkit-flex: 0 0 auto !important;

    flex: 0 0 auto !important;
}

.rejoindre-admin .button {
    padding: 6px 12px;
    border: none;
    background-color: #170e91;
    color: white;
    cursor: pointer;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    -webkit-transition: background-color 0.3s;

    transition: background-color 0.3s;
}

.rejoindre-admin .button:hover {
    background-color: #170e91;
}

.rejoindre-admin .button:focus {
    outline: none;
}

.rejoindre-admin .error-text {
    color: red; 
    font-size: 14px; 
    font-weight: 700;
}
.rejoindre-admin .button-new {
    margin: 24px;
}
.rejoindre-admin .button-form {
    width: 100%;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    display: flex;
}

.rejoindre-admin .tabs {
    display: flex;
    -webkit-justify-content: space-around;

    justify-content: space-around;
    margin-bottom: 20px;
    min-width: 50vw;
}

.rejoindre-admin .tab {
    cursor: pointer;
    padding: 10px 20px;
    background-color: lightgray;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    -webkit-transition: background-color 0.3s;

    transition: background-color 0.3s;
}

.rejoindre-admin .tab.active {
    background-color: #007BFF;
    color: white;
}
