.page-not-found {
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-align-items: center;

  align-items: center;
  -webkit-justify-content: center;

  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  background-color: var(--coode-NT-blanc);
}

.page-not-found h1 {
  font-size: 6rem;
  font-weight: bold;
  color: var(--coode-NT-blue);
}

.page-not-found p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.page-not-found a {
  display: inline-block;
  background-color: var(--coode-NT-blue);
  color: var(--coode-NT-blanc);
  padding: 10px 20px;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  -webkit-transition: background-color 0.3s ease;

  transition: background-color 0.3s ease;
}

.page-not-found a:hover {
  background-color: var(--coode-NT-blue);
}

@media (max-width: 768px) {
  .page-not-found h1 {
    font-size: 4rem;
  }

  .page-not-found p {
    font-size: 1.2rem;
  }
}