

.administration-projets-2 {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
}

.administration-projets-2 .content-section {
    -webkit-align-items: center;

    align-items: center;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 56px;
    padding: 24px 0;
    width: 90vw;
    max-width: 1126px;
}

.administration-projets-2 .text-wrapper-1 {

}

.administration-projets-2 .content-form-projet {

}

.administration-projets-2 .frame-2 {
    padding: 8px;
}

.administration-projets-2 .text-wrapper-2 {
    margin: 8px;
}

.administration-projets-2 .field {
    margin: 8px 0;
}

.administration-projets-2 .button-project[type="submit"]{
    background-color: var(--coode-NT-blue);
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 10px;
}

.administration-projets-2 .button-project[type="submit"]:hover {
    background-color: #0084c8;
}

.administration-projets-2 .button-cancel[type="button"] {
    background-color: #ac3030;
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.administration-projets-2 .button-cancel[type="button"]:hover {
    background-color: #ff1a1a;
}

.administration-projets-2 .projets-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    -webkit-justify-content: center;

    justify-content: center;
    padding: 20px;
    width: 800px;
    height: auto;
}

.administration-projets-2 .projets-card-2 {
    display: flex;
    -webkit-flex-direction: row;

    flex-direction: row;
    -webkit-align-items: center;

    align-items: center;
    width: 100%;
    background-color: #f9f9f9;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    -webkit-transition: transform 0.2s ease-in-out;

    transition: transform 0.2s ease-in-out;
}

.projets-card-2:hover {
    -webkit-transform: scale(1.05);

    transform: scale(1.05);
}

.detail-card {
    padding: 15px;
    -webkit-flex: 1;

    flex: 1;
    width: 100%;
}

.detail-card h3 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
}

.detail-card .p {
    font-size: 1em;
    color: #666;
    margin: 10px 0;
}

.detail-card button {
    padding: 10px 15px;
    background-color: #ac3030;
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;

    transition: background-color 0.3s;
    margin: 10px
}

.detail-card button:hover {
    background-color: #ff1a1a;
}

.imported-image {
    width: 100px;
    height: auto;
    -webkit-border-radius: 8px;

    border-radius: 8px;
}
