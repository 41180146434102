.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.children {
  min-height: 75vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;

    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-align-items: center;

  align-items: center;
  -webkit-justify-content: center;

  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);

    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);

    transform: rotate(360deg);
  }
}

.separation-line {
  width: 100%;
  height: 1px;
  -webkit-border-radius: 5%;

  border-radius: 5%;
  background: #25E89F;
  margin: 32px 0;
}

@media (max-width: 391px) {
  .separation-line {
    max-width: 342px;
  }
}