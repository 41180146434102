.propos {
  overflow: hidden;
  -webkit-align-items: center;

  align-items: center;
  background-color: #fcfcfa;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
}

.propos .auto-layout-2 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  padding: 7.5rem 0 5rem;
  position: relative;
  width: 90vw;
  max-width: 1124px;
  height: 261px;
}

.propos .icon-instance-node {
  -webkit-flex: 1;

  flex: 1;
  position: relative;
  padding-right: 40px;
}

.propos .icon-instance-node-2 {
  -webkit-flex: 1;

  flex: 1;
  position: relative;
  padding-left: 40px;
}

.propos img.icon-instance-node,
.propos img.icon-instance-node-2 {
  max-width: 100%;
  height: auto;
  padding-top: 8px;
  object-fit: contain; /* Adapte l'image au conteneur sans déformation */
  flex-shrink: 0; /* Empêche la compression de l'image */
}

.propos h1 {
  margin: 0;
}

.propos .auto-layout-vertical {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  display: flex;
  -webkit-flex: 1;

  flex: 1;
  -webkit-flex-direction: column;

  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
}

.propos .text-wrapper-5 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h1-font-family);
  font-size: var(--coode-NT-desktop-h1-font-size);
  font-style: var(--coode-NT-desktop-h1-font-style);
  font-weight: var(--coode-NT-desktop-h1-font-weight);
  letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
  line-height: var(--coode-NT-desktop-h1-line-height);
  position: relative;
}

.propos .text-wrapper-6 {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  padding-top: 16px;
  margin: 0;
  width: 100%;
}

.propos .text-wrapper-6-coodent {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  padding-top: 16px;
  margin: 0;
  width: 100%;
}

.propos .content-section {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 3.5rem;
  -webkit-justify-content: center;

  justify-content: center;
  padding: 5rem 0 5rem 0;
  position: relative;
  width: 90vw;
  max-width: 1124px;
}

.propos .text-wrapper-7 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h2-font-family);
  font-size: var(--coode-NT-desktop-h2-font-size);
  font-weight: var(--coode-NT-desktop-h2-font-weight);
  line-height: var(--coode-NT-desktop-h2-line-height);
  position: relative;
}

.propos .video-text-wrapper-7 {
  align-self: stretch;
  color: #170e91;
  font-family: var(--coode-NT-desktop-h2-font-family);
  font-size: var(--coode-NT-desktop-h2-font-size);
  font-weight: var(--coode-NT-desktop-h2-font-weight);
  line-height: var(--coode-NT-desktop-h2-line-height);
  position: relative;
}

.propos .video-text-wrapper-6 {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  width: 100%;
}

.propos .auto-layout-3 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;
}

.propos .text-wrapper-8 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h4-font-family, "Rubik", Helvetica);
  font-size: var(--coode-NT-desktop-h4-font-size, 24px);
  font-style: var(--coode-NT-desktop-h4-font-style, normal);
  font-weight: 550;
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing, 0px);
  line-height: var(--coode-NT-desktop-h4-line-height, normal);
  margin-top: -1px;
  position: relative;
}

.propos .title-wrapper-8 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h4-font-family, "Rubik", Helvetica);
  font-size: var(--coode-NT-desktop-h4-font-size, 24px);
  font-style: var(--coode-NT-desktop-h4-font-style, normal);
  font-weight: 550;
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing, 0px);
  line-height: var(--coode-NT-desktop-h4-line-height, normal);
  margin-top: -1px;
  margin-bottom: -4px;
  position: relative;
}

.propos .content-section-2 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  gap: 2.5rem;
  padding: 5rem 0;
  position: relative;
  width: 90vw;
  max-width: 1124px;
}

.propos .auto-layout-vertical-3 {
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  gap: 1rem;
  position: relative;
}

.propos .video-img {
  width: 322px;
  height: 428px;
  -webkit-border-radius: 16px;

  border-radius: 16px;
}

.propos .content-section-3 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  padding: 5rem 0 11.25rem 0;
  position: relative;
  width: 90vw;
  max-width: 1124px;
}

.propos .auto-layout-vertical-4 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 2rem;
  position: relative;
  width: 90vw;
  max-width: 934px;
  padding-bottom: 56px;
}

.propos .text-wrapper-9 {
  color: #170e91;
  font-family: var(--coode-NT-desktop-h2-font-family);
  font-size: var(--coode-NT-desktop-h2-font-size);
  font-weight: var(--coode-NT-desktop-h2-font-weight);
  line-height: var(--coode-NT-desktop-h2-line-height);
  text-align: right;
  width: fit-content;
}

.propos .p {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  line-height: var(--coode-NT-desktop-body-line-height);
  text-align: center;
  margin: 0;
}

.propos .auto-layout-4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
  grid-gap: 32px 20px;
  position: relative;
  width: 100%;
  justify-items: center;
}

.propos .frame-3 {
  -webkit-flex: 1 1 calc(33.333% - 1.25rem);

  flex: 1 1 calc(33.333% - 1.25rem);
  max-width: calc(33.333% - 1.25rem);
  min-width: 200px;
}

.propos .placeholder-image-15 {
  align-self: stretch;
  height: 316px;
}

.propos .text-wrapper-10 {
  color: #000000;
  font-family: var(--coode-NT-desktop-h3-font-family);
  font-weight: var(--coode-NT-desktop-h3-font-weight);
  width: fit-content;
}

.propos .text-wrapper-11,
.propos .text-wrapper-12 {
  align-self: stretch;
  color: #000000;
  font-family: var(--coode-NT-desktop-body-italic-font-family);
  font-size: var(--coode-NT-desktop-body-italic-font-size);
  font-style: var(--coode-NT-desktop-body-italic-font-style);
  line-height: var(--coode-NT-desktop-body-italic-line-height);
  text-align: center;
}

.propos .frame-3 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 1.5rem;
  padding: 0 0;
  position: relative;
  width: 266px;
}

.propos .frame-3 .imag-2 {
  width: 246px;
  height: 296px;
}

.propos .join-card {
  -webkit-transition: transform 0.3s;

  transition: transform 0.3s;
}

.propos .join-card:hover {
  -webkit-transform: scale(0.95);

  transform: scale(0.95);
}

.propos .join-card-content {
  display: flex;
  -webkit-justify-content: center;

  justify-content: center;
  -webkit-align-items: center;

  align-items: center;
  width: 246px;
  height: 296px;
}

.propos .video2 {
  padding-top: 40px;
}

/* Responsive pour mobile */

/* Styles pour les petits écrans (max-width: 391px) */
@media only screen and (max-width: 768px) {
  .propos .video2 {
    padding-top: 0;
  }

  /* Section Découvrez Coode-NT */
  .propos .auto-layout-2 {
    -webkit-flex-direction: column-reverse;

    flex-direction: column-reverse;
    gap: 0;
    padding: 0;
    padding-top: 48px;
    padding-bottom: 48px;
    height: 100%;
  }

  .propos .auto-layout-vertical-2 {
    gap: 0;
  }

  .propos .auto-layout-2 .discov-img {
    margin: 0;
    max-width: 100%;
  }

  .propos .text-wrapper-6 {
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-style: var(--coode-NT-mobile-body-font-style);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

  .propos .text-wrapper-6-coodent {
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-style: var(--coode-NT-mobile-body-font-style);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

  /* Section Nos Valeurs */
  .propos .content-section {
    padding: 48px 0 48px 0;
    margin: 0;
    gap: 0;
  }

  .propos .text-wrapper-7 {
    font-family: var(--coode-NT-mobile-h2-font-family);
    font-size: var(--coode-NT-mobile-h2-font-size);
    font-style: var(--coode-NT-mobile-h2-font-style);
    font-weight: var(--coode-NT-mobile-h2-font-weight);
    letter-spacing: var(--coode-NT-mobile-h2-letter-spacing);
    line-height: var(--coode-NT-mobile-h2-line-height);
  }

  .propos .auto-layout-3 {
    gap: 0;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .propos .encouragement {
    -webkit-flex-direction: column;

    flex-direction: column;
  }

  .propos .auto-layout-3 .icon-instance-node-2 {
    padding-left: 0;
  }

  .propos .icon-instance-node {
    margin-top: 40px;
    max-width: 100%;
    padding: 0;
  }

  .propos .icon-instance-node-2 {
    margin-top: 40px;
    max-width: 100%;
    padding: 0;
  }

  .propos .auto-layout-vertical {
    gap: 0;
  }

  .propos .text-wrapper-8 {
    font-family: var(--coode-NT-mobile-h4-font-family);
    font-size: var(--coode-NT-mobile-h4-font-size);
    font-style: var(--coode-NT-mobile-h4-font-style);
    font-weight: var(--coode-NT-mobile-h4-font-weight);
    letter-spacing: var(--coode-NT-mobile-h4-letter-spacing);
    line-height: var(--coode-NT-mobile-h4-line-height);
    margin-top: 24px;
  }

  .propos .encouragement {
    margin-bottom: 0;
  }

  .propos .accompagnement,
  .propos .ecoute {
    -webkit-flex-direction: column-reverse;

    flex-direction: column-reverse;
  }

  .propos .title-wrapper-8 {
    font-family: var(--coode-NT-mobile-h4-font-family);
    font-size: var(--coode-NT-mobile-h4-font-size);
    font-style: var(--coode-NT-mobile-h4-font-style);
    font-weight: var(--coode-NT-mobile-h4-font-weight);
    letter-spacing: var(--coode-NT-mobile-h4-letter-spacing);
    line-height: var(--coode-NT-mobile-h4-line-height);
    margin: 24px 0 0;
  }

  /* Section Vidéo */
  .propos .content-section-2 {
    display: flex;
    padding: 48px;
    gap: 0;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: start;

    align-items: start;
  }
  .propos .auto-layout-vertical-3 {
    gap: 0;
    margin-bottom: 32px;
  }

  .propos .video-text-wrapper-7 {
    align-self: stretch;
    color: #170e91;
    font-family: var(--coode-NT-mobile-h2-font-family);
    font-size: var(--coode-NT-mobile-h2-font-size);
    font-weight: var(--coode-NT-mobile-h2-font-weight);
    line-height: var(--coode-NT-mobile-h2-line-height);
    position: relative;
    margin-bottom: 24px;
  }

  .propos .video-text-wrapper-6 {
    align-self: stretch;
    color: #000000;
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
    position: relative;
    width: 100%;
  }

  .propos .video-img {
    width: 100%;
    display: flex;
    -webkit-align-items: center;

    align-items: center;
  }

  /* Section Notre Équipe */
  .propos .content-section-3 {
    margin-top: 0;
    padding: 48px 0 180px 0;
    gap: 0;
  }

  .propos .auto-layout-vertical-4 {
    gap: 0;
    margin: 0;
    padding-bottom: 32px;
  }

  .propos .video-img {
    width: 100%;
    display: flex;
    -webkit-align-items: center;

    align-items: center;
  }
  .propos .text-wrapper-9 {
    margin-bottom: 16px;
    font-family: var(--coode-NT-mobile-h2-font-family);
    font-size: var(--coode-NT-mobile-h2-font-size);
    font-weight: var(--coode-NT-mobile-h2-font-weight);
    letter-spacing: var(--coode-NT-mobile-h2-letter-spacing);
    line-height: var(--coode-NT-mobile-h2-line-height);
  }

  .propos .auto-layout-vertical-4 .p {
    margin: 0;
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

  .propos .auto-layout-4 {
    display: flex;
    overflow-x: scroll;
    padding: 0 24px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* Masquer la barre de défilement sur les navigateurs WebKit (Chrome, Safari) */
    scrollbar-width: none;
    /* Masquer la barre de défilement sur Firefox */
  }

  .propos .auto-layout-4::-webkit-scrollbar {
    display: none;
    /* Masquer la barre de défilement pour WebKit */
  }

  .propos .frame-3 {
    margin: 0;
    padding: 0;
    width: 134px;
    gap: 16px;
    max-width: 100%;
    min-width: 100%;
  }

  .propos .text-wrapper-9 {
    margin-bottom: 16px;
    font-family: var(--coode-NT-mobile-h2-font-family);
    font-size: var(--coode-NT-mobile-h2-font-size);
    font-weight: var(--coode-NT-mobile-h2-font-weight);
    letter-spacing: var(--coode-NT-mobile-h2-letter-spacing);
    line-height: var(--coode-NT-mobile-h2-line-height);
  }

  .propos .auto-layout-vertical-4 .p {
    margin: 0;
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

  .propos .auto-layout-4 {
    display: flex;
    overflow-x: scroll;
    padding: 0 24px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* Masquer la barre de défilement sur les navigateurs WebKit (Chrome, Safari) */
    scrollbar-width: none;
    /* Masquer la barre de défilement sur Firefox */
  }

  .propos .auto-layout-4::-webkit-scrollbar {
    display: none;
    /* Masquer la barre de défilement pour WebKit */
  }

  .propos .frame-3 {
    margin: 0;
    padding: 0;
    width: 134px;
    gap: 16px;
    max-width: 100%;
    min-width: 100%;
  }

  .propos .frame-3 .imag-2 {
    margin: 0;
  }

  .propos .join-card-content {
    margin: 0;
    width: 134px;
    height: 158px;
    gap: 0;
  }
}

@media only screen and (max-width: 430px) {
  .propos .frame-3 {
    margin: 0;
    padding: 0;
    width: 134px;
    gap: 8px;
    max-width: 100%;
    min-width: 100%;
  }
  .propos .frame-3 .imag-2 {
    max-width: 100%;
    height: auto;
    padding-top: 8px;
    object-fit: contain; /* Adapte l'image au conteneur sans déformation */
    flex-shrink: 0; /* Empêche la compression de l'image */
    margin: 0;
  }

  .propos .join-card-content {
    margin: 0;
    margin-top: 8px;
    width: 130px;
    height: 160px;
    gap: 0;
  }
}

@media only screen and (max-width: 375px) {
  .propos .auto-layout-4 {
    display: flex;
    overflow-x: scroll;
    padding: 0 24px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* Masquer la barre de défilement sur les navigateurs WebKit (Chrome, Safari) */
    scrollbar-width: none;
    /* Masquer la barre de défilement sur Firefox */
  }

  .propos .frame-3 {
    margin: 0;
    padding: 0;
    width: 110px;
    gap: 16px;
    max-width: 100%;
    min-width: 100%;
  }

  .propos .frame-3 .imag-2 {
    max-width: 100%;
    height: auto;
    padding-top: 8px;
    object-fit: contain; /* Adapte l'image au conteneur sans déformation */
    flex-shrink: 0; /* Empêche la compression de l'image */
    margin: 0;
    width: 130px;
    height: 160px;
  }

  .propos .join-card-content {
    margin: 0;
    margin-top: 24px;
    width: 110px;
    height: 130px;
    gap: 0;
  }
}

.video-react .video-react-big-play-button {
  font-size: 20em !important;
  border: 0 !important;
  background-color: transparent !important;
  color: #170e91 !important;
  opacity: 0.6 !important;
}

.video-react .video-react-big-play-button:hover {
  color: white !important;
}

@supports (-webkit-touch-callout: none) {
  .propos .title-wrapper-8 {
    margin: 0;
  }

  .propos .text-wrapper-8 {
    margin-top: 0;
  }

  .propos .video-react {
    margin-bottom: 24px;
  }
}
