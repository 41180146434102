.contact {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    position: relative;
}

.field .custom-select {
    background-color: #fcfcfa;
}

.contact input[type="text"]:focus,
.contact input[type="email"]:focus,
.contact input[type="tel"]:focus,
.contact textarea:focus,
.contact input[type="file"]:focus,
.contact select:focus {
    outline: 1px solid #170e91;
    border-color: #170e91;
}

.contact input[type="text"],
.contact input[type="email"],
.contact input[type="tel"],
.contact textarea,
.contact select {
    width: 100%;
    resize: vertical;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    font-size: 16px;
    margin-top: 8px;
}

.contact input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
}


.contact .design-component-instance-node-2 {
    background-color: #170e91;
    border-color: unset;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
}

.contact .content-section {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    gap: 20px;
    -webkit-justify-content: center;

    justify-content: center;
    padding: 7.5rem 0 11.25rem 0;
    position: relative;
    max-width: 1126px;
}

.contact .frame-2 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 56px;
    -webkit-justify-content: center;

    justify-content: center;
    position: relative;
    width: 553px;
}

.contact .auto-layout-vertical {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
}

.contact .error {
    color: red;
    font-size: 14px;
    font-weight: 500;
    max-height: 100%;

}

.contact .text-wrapper-5 {
    align-self: stretch;
    color: #170e91;
    font-family: var(--coode-NT-desktop-h1-font-family);
    font-size: var(--coode-NT-desktop-h1-font-size);
    font-style: var(--coode-NT-desktop-h1-font-style);
    font-weight: var(--coode-NT-desktop-h1-font-weight);
    letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
    line-height: var(--coode-NT-desktop-h1-line-height);
    margin-top: -1px;
    position: relative;
}

.contact .partagez-nous-vos-id {
    align-self: stretch;
    color: #000000;
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
    position: relative;
    margin: 0;
}

.contact .frame-3 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 56px;
    -webkit-justify-content: center;

    justify-content: center;
    position: relative;
    width: 100%;
}

.contact .frame-4 {
    -webkit-align-items: center;

    align-items: center;
    display: inline-flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.contact .rectangle {
    position: relative;

}

.contact .text-wrapper-6 {
    color: #000000;
    font-family: var(--coode-NT-desktop-body-bold-font-family);
    font-size: var(--coode-NT-desktop-body-bold-font-size);
    font-style: var(--coode-NT-desktop-body-bold-font-style);
    font-weight: var(--coode-NT-desktop-body-bold-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing);
    line-height: var(--coode-NT-desktop-body-bold-line-height);
    text-decoration: none;
}

/* Contour du formulaire */
.contact .frame-5 {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    border: 1px solid;
    border-color: #655EC9;
    -webkit-border-radius: 16px;

    border-radius: 16px;
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    padding: 40px;
    position: relative;
}

.contact .div-5 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 32px;
}

.contact .text-wrapper-7 {
    align-self: stretch;
    color: #000000;
    font-family: var(--coode-NT-desktop-body-bold-font-family);
    font-size: var(--coode-NT-desktop-body-bold-font-size);
    font-style: var(--coode-NT-desktop-body-bold-font-style);
    font-weight: var(--coode-NT-desktop-body-bold-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-bold-letter-spacing);
    line-height: var(--coode-NT-desktop-body-bold-line-height);
    position: relative;
    display: inline-block;
    width: 100%;
}

.contact .field {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    align-self: stretch;
    height: 44px;
    position: relative;
    width: 473px;
}

.contact .field select {
    -webkit-appearance: none;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

.contact .field .select-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-25%);

    transform: translateY(-25%);
    pointer-events: none;
    color: #888;
    font-size: 18px;
}

.contact .spacer {
    -webkit-border-radius: 1px;

    border-radius: 1px;
    -webkit-flex: 1;

    flex: 1;
    -webkit-flex-grow: 1;

    flex-grow: 1;
    height: 22px;
    position: relative;
}

/* Input Nom et Prénom */
.contact .input-field {
    align-self: stretch;
    height: 44px;
    position: relative;
    width: 473px;
    display: flex;
}

.contact .field-3 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    height: auto;
    overflow: hidden;
    position: relative;
    width: 473px;
}

/* Styles pour mobile (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .contact .content-section {
        -webkit-flex-direction: column;

        flex-direction: column;
        -webkit-align-items: flex-start;

        align-items: flex-start;
        padding: 48px 24px 180px 24px;
        gap: 48px;
    }

    .contact .frame-2,
    .contact .frame-3,
    .contact .frame-4 {
        width: 100%;
        padding: 0;
        gap: 0;
    }

    .contact .frame-4:not(:first-child) {
        margin-top: 32px;
    }

    .contact .auto-layout-vertical {
        gap: 0;
        width: 100%;
    }

    .contact .frame-4 .text-wrapper-6 {
        font-family: var(--coode-NT-mobile-body-bold-font-family);
        font-size: var(--coode-NT-mobile-body-bold-font-size);
        font-style: var(--coode-NT-mobile-body-bold-font-style);
        font-weight: var(--coode-NT-mobile-body-bold-font-weight);
        letter-spacing: var(--coode-NT-mobile-body-bold-letter-spacing);
        line-height: var(--coode-NT-mobile-body-bold-line-height);
    }

    .contact .text-wrapper-5 {
        font-family: var(--coode-NT-mobile-h1-font-family);
        font-size: var(--coode-NT-mobile-h1-font-size);
        font-style: var(--coode-NT-mobile-h1-font-style);
        letter-spacing: var(--coode-NT-mobile-h1-letter-spacing);
        line-height: var(--coode-NT-mobile-h1-line-height);
        position: relative;
    }

    .contact .partagez-nous-vos-id {
        margin: 0;
        padding-block: 32px;
    }

    .contact .frame-5 .div-5 .text-wrapper-7,
    .contact .frame-5 .div-5 .text-wrapper-6 {
        font-family: var(--coode-NT-mobile-body-font-family);
        font-size: var(--coode-NT-mobile-body-font-size);
        font-style: var(--coode-NT-mobile-body-font-style);
        font-weight: var(--coode-NT-mobile-body-font-weight);

    }



    .contact .frame-5 .div-5 .text-wrapper-6,
    .contact .text-wrapper-7 {
        margin-left: 0;
    }

    .contact .rectangle {
        height: 24px;
        width: 24px;
        margin-right: 16px;
    }

    .contact .frame-5 {
        width: 100%;
        max-width: 278px;
        margin: 0;
        padding: 32px;
    }

    .contact .field,
    .contact .input-field,
    .contact .field-3 {
        width: 100%;
        padding: 0;
    }

    .contact .field {
        -webkit-align-items: center;

        align-items: center;
    }

    .contact .field .select-icon {
        position: absolute;
        right: 16px;
        top: 38%;
        -webkit-transform: translateY(10%);

        transform: translateY(10%);
        pointer-events: none;
        color: #888;
        font-size: 16px;
    }

    .contact input[type="text"],
    .contact input[type="email"],
    .contact input[type="tel"],
    .contact select,
    .contact textarea {
        font-size: 16px;
        -webkit-border-radius: 8px;

        border-radius: 8px;
    }

    .contact .error {
        font-size: 12px;
        margin-top: 8px;
    }

    .contact .spacer {
        display: none;
        height: 0;
    }
}