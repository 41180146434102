.cgu-contenaire {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    position: relative;
    padding: 0px;
}

.cgu-contenaire .div-1 {
    max-width: 1126px;
    padding: 120px 0 180px 0;
}

.cgu-contenaire h1,
.cgu-contenaire h2 {
    color: var(--coode-NT-blue);
}

.cgu-contenaire h1 {
    font-family: var(--coode-NT-desktop-h1-font-family);
    font-size: var(--coode-NT-desktop-h1-font-size);
    font-style: var(--coode-NT-desktop-h1-font-style);
    font-weight: var(--coode-NT-desktop-h1-font-weight);
    text-align: left;
    margin-bottom: 20px;
}

.cgu-contenaire h2 {
    margin-top: 20px;
    font-family: var(--coode-NT-desktop-h2-font-family);
    font-size: var(--coode-NT-desktop-h2-font-size);
    font-style: var(--coode-NT-desktop-h2-font-style);
    font-weight: var(--coode-NT-desktop-h2-font-weight);
    text-align: left;
}

.cgu-contenaire .date {
    color: var(--coode-NT-violet-clair);
    font-size: 0.9em;
    margin-bottom: 48px;
}

.cgu-contenaire p li {
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    text-align: left;
    margin-bottom: 48px;
}

/* Styles pour mobile (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .cgu-contenaire {
        overflow: hidden;
        margin: 48px 24px 180px 24px;
    }

    .cgu-contenaire .div-1 {
        gap: 24px;
        padding: 0;
    }

    .cgu-contenaire h1 {
        font-family: var(--coode-NT-mobile-h1-font-family);
        font-size: var(--coode-NT-mobile-h1-font-size);
        font-style: var(--coode-NT-mobile-h1-font-style);
        font-weight: var(--coode-NT-mobile-h1-font-weight);
    }

    .cgu-contenaire h2 {
        margin-top: 20px;
        font-family: var(--coode-NT-mobile-h2-font-family);
        font-size: var(--coode-NT-mobile-h2-font-size);
        font-style: var(--coode-NT-mobile-h2-font-style);
        font-weight: var(--coode-NT-mobile-h2-font-weight);
    }

    .cgu-contenaire p li {
        font-family: var(--coode-NT-mobile-body-font-family);
        font-size: var(--coode-NT-mobile-body-font-size);
        font-style: var(--coode-NT-mobile-body-font-style);
        font-weight: var(--coode-NT-mobile-body-font-weight);
    }
}