.evaluate {
  display: flex;
  width: 1124px;
  padding: 64px 229px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  font-family: var(--coode-NT-desktop-body-font-family);
}

.evaluate .groupTitle {
  display: flex;
  padding: 0px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.evaluate .title {
  color: var(--text-text-primary, black);
  text-align: center;

  /* Coode-NT/Desktop/H1 */
  font-family: var(--font-family-titre, "Arial Black");
  font-size: var(--font-size-xxl, 40px);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.evaluate .subTitle {
  color: var(--text-text-primary, black);
  text-align: center;

  /* DomiSimpli/Desktop/Minihead */
  color: #000;
  text-align: center;

  /* Coode-NT/Desktop/Body Bold */
  font-family: var(--font-family-texte, Rubik);
  font-size: var(--font-size-xs, 18px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.evaluate .content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

.evaluate .leftPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 448px;
  gap: 16px;
  flex: 1 0 0;
  color: var(--text-text-primary, black);

  font-size: var(--font-size-xs, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-sm, 24px);
}

.evaluate .phase {
  font-size: var(--font-size-xl, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.evaluate .youtube {
  background: #dedcf0;
}

@media only screen and (max-width: 430px) {
  .accueil .bloc-evaluate {
    width: 90%;
    margin: 0 24px 8px;
  }

  .evaluate {
    width: 342px;
    padding: 32px 24px;
    gap: 48px;
  }

  .evaluate .title {
    /* Coode-NT/Desktop/H1 */
    font-family: var(--font-family-titre, "Arial Black");
    font-size: var(--font-size-xl, 32px);

    font-weight: 600;
    line-height: var(--font-line-height-lg, 40px); /* 125% */
  }
  .evaluate .subTitle {
    /* Coode-NT/Desktop/Body Bold */
    font-size: var(--font-size-xxs, 16px);
    font-weight: 500;
    line-height: 24px;
  }
  .evaluate .content {
    flex-direction: column;
    gap: 24px;
  }

  .evaluate .leftPart {
    width: 90%;
    align-self: stretch;
    font-size: var(--font-size-xxs, 16px);
  }

  .evaluate .phase {
    font-size: var(--font-size-l, 28px);
  }

  .evaluate .youtube {
    height: 245px;
    align-self: stretch;
  }
}
