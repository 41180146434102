.projets-container .content-section-2 {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 56px;
    -webkit-justify-content: center;

    justify-content: center;
    padding: 0px;
    position: relative;
    width: 90vw;
    max-width: 1126px;
}

.projets-container .auto-layout-2 {
    display: flex;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    -webkit-flex-direction: row;

    flex-direction: row;
    gap: 20px;
    position: relative;
    width: 100%;
}

.projets-container .auto-layout-vertical {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    display: flex;
    -webkit-flex: 1;

    flex: 1;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 32px;
    position: relative;
}

.projets-container .text-wrapper-6 {
    align-self: stretch;
    color: #170e91;
    font-family: var(--coode-NT-desktop-h4-font-family);
    font-size: var(--coode-NT-desktop-h4-font-size);
    font-style: var(--coode-NT-desktop-h4-font-style);
    font-weight: var(--coode-NT-desktop-h4-font-weight);
    letter-spacing: var(--coode-NT-desktop-h4-letter-spacing);
    line-height: var(--coode-NT-desktop-h4-line-height);
    margin: 0 0 16px;
    position: relative;
}

.projets-container p {
    align-self: stretch;
    color: #000000;
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
    position: relative;
    margin: 0;
}

.projets-container .design-component-instance-node-2 {
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
}

.projets-container .placeholder-image {
    align-self: stretch ;
    -webkit-flex: 1;

    flex: 1;
    -webkit-flex-grow: 1;

    flex-grow: 1;
    position: relative;
    gap: 8px;
}

.projets-container .imported-image {
    /*max-height: 100%; /* Pour que l'image ne dépasse pas la hauteur de son conteneur */
    width: auto;      /* Conserver les proportions */
    object-fit: cover; /* Ajuster l'image pour qu'elle couvre l'espace sans déformer */
    align-self: flex-start; /* Aligner l'image en haut du conteneur */
    -webkit-flex: 0 0 auto !important; /* Ne pas permettre à l'image de croître */

    flex: 0 0 auto !important; /* Ne pas permettre à l'image de croître */
    position: relative !important;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    max-height: 336px;
}

@media only screen and (max-width: 768px) {

  .projets-container {
    width: 100%;
    -webkit-align-items: center;

    align-items: center;
  }

  .projets-container .content-section-2 {
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  
  .projets-container .auto-layout-2 {
    -webkit-flex-direction: column;

    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .projets-container .auto-layout-2 {
    -webkit-flex-direction: column-reverse;

    flex-direction: column-reverse;

  }

  .projets-container .text-wrapper-6 {
    font-family: var(--coode-NT-mobile-h4-font-family);
    font-size: var(--coode-NT-mobile-h4-font-size);
    font-style: var(--coode-NT-mobile-h4-font-style);
    font-weight: var(--coode-NT-mobile-h4-font-weight);
    letter-spacing: var(--coode-NT-mobile-h4-letter-spacing);
    line-height: var(--coode-NT-mobile-h4-line-height);
    margin: 32px 0 16px;
  }

  .projets-container p {
    font-family: var(--coode-NT-mobile-body-font-family);
    font-size: var(--coode-NT-mobile-body-font-size);
    font-style: var(--coode-NT-mobile-body-font-style);
    font-weight: var(--coode-NT-mobile-body-font-weight);
    letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
    line-height: var(--coode-NT-mobile-body-line-height);
  }

    .projets-container .auto-layout-vertical {
        margin: 0 0 48px;
        width: 100%;
        gap: 0px;
        -webkit-align-items: center;

        align-items: center;
    }

    .projets-container .auto-layout-vertical-2 {
      margin-bottom: 24px;
    }


  .projets-container .imported-image {
      width: 100%;
  }
}