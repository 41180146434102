.modale-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    -webkit-align-items: center;

    align-items: center;
    z-index: 1050; 
    -webkit-transition: opacity 0.3s ease;

    transition: opacity 0.3s ease;
  }
  
  .modale {
    background-color: white;
    padding: 30px;
    -webkit-border-radius: 10px;

    border-radius: 10px;
    -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);

    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%; 
    -webkit-animation: slideIn 0.3s ease;

    animation: slideIn 0.3s ease;
  }
  
  .modale-header {
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    -webkit-align-items: center;

    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px; 
  }
  
  .modale-title {
    margin: 0;
    font-size: 1.5rem; 
    color: #333;
  }
  
  .close-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.5rem;
    color: #888; 
    -webkit-transition: color 0.3s ease;

    transition: color 0.3s ease;
  }


.close-button:hover {
  color: #333; 
}
  
  /* Styles pour le succès et l'échec */
  .add-success {
    background-color: #dff0d8; 
    border-left: 5px solid #4cae4c;
  }
  
  .add-error {
    background-color: #f2dede; 
    border-left: 5px solid #d9534f; 
  }

  .info {
    background-color: #d9edf7; 
    border-left: 5px solid #5bc0de;
  }

  /* Animation pour la modale */
@keyframes slideIn {
  from {
      -webkit-transform: translateY(-20px);

      transform: translateY(-20px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateY(0);

      transform: translateY(0);
      opacity: 1;
  }
}
  