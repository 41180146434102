.etre-averti-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    -webkit-align-items: center;

    align-items: center;
    -webkit-flex-direction: column;

    flex-direction: column;
    z-index: 10
}

.etre-averti-content {
    background: white;
    padding: 20px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    -webkit-animation: slideIn 0.3s ease;

    animation: slideIn 0.3s ease;
}

.etre-averti-content h2 {
    color: var(--coode-NT-blue);
    margin-top: 0;
    text-align: center;
    margin-bottom: 20px;
}

.etre-averti-content 
input[type="text"],
.etre-averti-content
input[type="email"] {
    
    padding: 8px;
    width: 95%;
}

.etre-averti-buttons {
    display: flex;
    -webkit-justify-content: flex-end;

    justify-content: flex-end;
    gap: 16px;
}

/* Styles pour mobile (max-width: 768px) */
@media only screen and (max-width: 768px) {

    .etre-averti-content {
        width: 80%;
    }

}