.nos-projets {
    -webkit-align-items: center;

    align-items: center;
    background-color: #fcfcfa;
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    padding: 0 16px;
}

.nos-projets .content-section {
    -webkit-align-items: center;

    align-items: center;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 56px;
    padding: 120px 0 80PX;
    width: 90vw;
    max-width: 1126px;
}

.nos-projets .text-wrapper-5 {
    align-self: stretch;
    color: #170e91;
    font-family: var(--coode-NT-desktop-h1-font-family);
    font-size: var(--coode-NT-desktop-h1-font-size);
    font-style: var(--coode-NT-desktop-h1-font-style);
    font-weight: var(--coode-NT-desktop-h1-font-weight);
    letter-spacing: var(--coode-NT-desktop-h1-letter-spacing);
    line-height: var(--coode-NT-desktop-h1-line-height);
    position: relative;
    margin: 0;
}

.projets-container .placeholder-image-3 {
    width: 563px;
}

.nos-projets .CTA {
    -webkit-align-items: center;

    align-items: center;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    gap: 20px;
    padding-bottom: 180px;
    padding-top: 80px;
    position: relative;
    width: 90vw;
    max-width: 1126px;
}

.nos-projets .auto-layout-vertical-3 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    display: flex;
    -webkit-flex: 1;

    flex: 1;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 40px;
    position: relative;
}

.nos-projets .auto-layout-vertical-4 {
    -webkit-align-items: flex-start;

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    -webkit-flex: 0 0 auto;

    flex: 0 0 auto;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 32px;
    position: relative;

}

.nos-projets .text-wrapper-7 {
    color: #170e91;
    font-family: var(--coode-NT-desktop-h2-font-family);
    font-size: var(--coode-NT-desktop-h2-font-size);
    font-style: var(--coode-NT-desktop-h2-font-style);
    font-weight: var(--coode-NT-desktop-h2-font-weight);
    letter-spacing: var(--coode-NT-desktop-h2-letter-spacing);
    line-height: var(--coode-NT-desktop-h2-line-height);
    position: relative;
    width: 548px;
    margin: 0;
}

.CTA .p {
    align-self: stretch;
    color: #000000;
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
    position: relative;
    margin: 0;
}

/* Styles pour mobile (max-width: 768px) */
@media only screen and (max-width: 768px) {

    .nos-projets {
        padding: 0 24px 180px;
    }


    .nos-projets .content-section {
        -webkit-flex-direction: column;

        flex-direction: column;
        padding: 48px 0 0;
        gap: 32px;
        width: 100%;
    }

    .nos-projets .CTA {
        -webkit-flex-direction: column;

        flex-direction: column;
        padding-block: 48px;
        gap: 32px;
        width: 100%;
        -webkit-flex-direction: column-reverse;

        flex-direction: column-reverse;
        padding: 48px 0 0px;
    }

    .nos-projets .text-wrapper-5 {
        font-family: var(--coode-NT-mobile-h1-font-family);
        font-size: var(--coode-NT-mobile-h1-font-size);
        font-style: var(--coode-NT-mobile-h1-font-style);
        font-weight: var(--coode-NT-mobile-h1-font-weight);
        letter-spacing: var(--coode-NT-mobile-h1-letter-spacing);
        line-height: var(--coode-NT-mobile-h1-line-height);
        padding-bottom: 0;
    }

    .nos-projets .text-wrapper-7 {
        width: 100%;
        font-family: var(--coode-NT-mobile-h2-font-family);
        font-size: var(--coode-NT-mobile-h2-font-size);
        font-style: var(--coode-NT-mobile-h2-font-style);
        font-weight: var(--coode-NT-mobile-h2-font-weight);
        letter-spacing: var(--coode-NT-mobile-h2-letter-spacing);
        line-height: var(--coode-NT-mobile-h2-line-height);
        margin: 0;
    }

    .nos-projets .p {
        font-family: var(--coode-NT-mobile-body-font-family);
        font-size: var(--coode-NT-mobile-body-font-size);
        font-style: var(--coode-NT-mobile-body-font-style);
        font-weight: var(--coode-NT-mobile-body-font-weight);
        letter-spacing: var(--coode-NT-mobile-body-letter-spacing);
        line-height: var(--coode-NT-mobile-body-line-height);
        margin: 0;
    }

    .nos-projets .auto-layout-vertical-3,
    .nos-projets .auto-layout-vertical-4 {
        width: 100%;
        -webkit-flex-direction: column;

        flex-direction: column;
        gap: 32px;
    }

    .projets-container .placeholder-image-3 {
        display: flex;
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }

    .nos-projets .placeholder-image {
        max-width: 100%;
    }

}