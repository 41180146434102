.style-outlined-wrapper {
    all: unset;
    -webkit-align-items: center;

    align-items: center;
    border: 1px solid;
    border-color: #170e91;
    -webkit-border-radius: 50px;

    border-radius: 50px;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
    display: inline-flex;
    gap: 10px;
    -webkit-justify-content: center;

    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    cursor: pointer; /* Changement du curseur pour pointer */
    -webkit-transition: border-color 0.3s ease; /* Animation fluide pour le changement de bordure */

    transition: border-color 0.3s ease; /* Animation fluide pour le changement de bordure */
}

.style-outlined-wrapper .do-something-2 {
    font-family: var(--coode-NT-desktop-body-font-family);
    font-size: var(--coode-NT-desktop-body-font-size);
    font-style: var(--coode-NT-desktop-body-font-style);
    font-weight: var(--coode-NT-desktop-body-font-weight);
    letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
    line-height: var(--coode-NT-desktop-body-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
}

.style-outlined-wrapper.style-0-filled {
    background-color: #170e91;
}

.style-outlined-wrapper.style-0-filled .do-something-2 {
    color: var(--neutral-0);
}

.style-outlined-wrapper.style-0-outlined .do-something-2 {
    color: #170e91;
}

/* Ajout de la classe clicked pour l'effet de rétrécissement */
.style-outlined-wrapper.clicked {
    -webkit-transform: scale(0.95); /* Rétrécissement de 5% lors du clic */

    transform: scale(0.95); /* Rétrécissement de 5% lors du clic */
}