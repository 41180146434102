.admin-team-management {
  max-width: 900px;
  padding: 20px;
  background-color: #f0f0f5;
  -webkit-border-radius: 10px;

  border-radius: 10px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  margin-left: 4%;
}

.admin-team-management h2 {
  text-align: center;
  color: #170e91;
  font-size: 26px;
  margin-bottom: 20px;
}

.admin-team-management h3 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 24px;
  color: #000;
}

.admin-team-management ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-team-management ul li {
  -webkit-border-radius: 10px;

  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  -webkit-align-items: center;

  align-items: center;
  -webkit-justify-content: space-between;

  justify-content: space-between;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-transition: transform 0.2s ease-in-out;

  transition: transform 0.2s ease-in-out;
}

.admin-team-management ul li:hover {
  -webkit-transform: translateY(-5px);

  transform: translateY(-5px);
}

.admin-team-management ul li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  -webkit-border-radius: 50%;

  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #170e91 ;
}

.admin-team-management ul li .member-info {
  display: flex;
  -webkit-align-items: center;

  align-items: center;
  -webkit-flex-grow: 1;

  flex-grow: 1;
  margin-left: 15px;
}

.admin-team-management ul li p {
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.admin-team-management ul li p.title {
  font-size: 14px;
  color: #888;
}

.admin-team-management .action-icons {
  display: flex;
  gap: 10px;
}

.admin-team-management .action-icons .fa-edit {
  color: #007bff;
}

.admin-team-management .action-icons .fa-trash {
  color: #dc3545;
}

.admin-team-management .action-icons .fa-edit:hover {
  color: #0056b3;
}

.admin-team-management .action-icons .fa-trash:hover {
  color: #c82333;
}

.admin-team-management button {
  padding: 10px 20px;
  border: none;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: background-color 0.3s ease;

  transition: background-color 0.3s ease;
}

.admin-team-management button:hover {
  background-color: #218838;
}

.admin-team-management form {
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.admin-team-management input[type="text"],
.admin-team-management input[type="file"] {
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

.admin-team-management input::placeholder {
  color: #aaa;
}

.add-member-button {
  display: flex;
  -webkit-justify-content: center;

  justify-content: center;
  margin-top: 20px;
}

.add-member-button button {
  background-color: #170e91;
  border: none;
  -webkit-border-radius: 50%;

  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: white;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;

  transition: background-color 0.3s ease;
  display: flex;
  -webkit-justify-content: center;

  justify-content: center;
  -webkit-align-items: center;

  align-items: center;
}

.add-member-button button:hover {
  background-color: #2820d1;
}

form input {
  margin-bottom: 10px;
}

/* Couleurs des membres selon leur rôle */
.member-item.role-developpeur {
    background-color: #e0f7fa; /* Couleur pour Développeur */
}

.member-item.role-direction {
    background-color: #ffe0b2; /* Couleur pour Direction */
}

.member-item.role-ux-ui-designer {
    background-color: #e1bee7; /* Couleur pour Designer */
}

.member-item {
    padding: 10px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    -webkit-align-items: center;

    align-items: center;
}

.member-item img {
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-right: 10px;
}



