.accueil-admin {}

.accueil-admin form {
    max-width: fit-content;
}

.accueil-admin table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.accueil-admin table {
    border: 1px solid #655ec9;
}
.accueil-admin th {
    border: 1px solid #655ec9;
}
.accueil-admin td {
    border: 1px solid #655ec9;
}
.accueil-admin td {
    background-color: #f9f9f9;
    text-align: left;
    vertical-align: middle;
    padding: 10px;
}

.accueil-admin td.long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.accueil-admin th {
    background-color: #655ec9;
    color: white;
    text-transform: uppercase;
}

.accueil-admin td {
    background-color: #f9f9f9;
}