.admin-objet-contact {
    max-width: 700px; 
    margin: 20px auto; 
    padding: 30px; 
    border: 1px solid #ddd; 
    -webkit-border-radius: 12px; 

    border-radius: 12px; 
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: #f9f9f9; 
}

.admin-objet-contact h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
}

.admin-objet-contact form {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    margin-bottom: 30px;
}

.admin-objet-contact button{
    background-color: transparent;
}

.admin-objet-contact .add{
    background-color: #170e91;
    font-weight: 700;
}

.admin-objet-contact input[type="text"] {
    width: auto;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    -webkit-border-radius: 6px;

    border-radius: 6px;
    font-size: 16px;
    -webkit-transition: border-color 0.3s;

    transition: border-color 0.3s;
}

.admin-objet-contact input[type="text"]:focus {
    border-color: #170e91; 
    outline: none;
}

.admin-objet-contact ul {
    list-style-type: none;
    padding: 0;
}

.admin-objet-contact li {
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    -webkit-align-items: center;

    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #eee;
}

.admin-objet-contact li:hover {
    background-color: #f1f1f1;
}

.admin-objet-contact li .icon {
    font-size: 18px; 
    margin-left: 10px;
}

.admin-objet-contact .error {
    color: red;
    font-size: 14px;
}

.admin-objet-contact .edit{
   color: #170e91 ; 
}

.admin-objet-contact .edit:hover{
    color: #0056b3 ; 
 }

.admin-objet-contact .trash{
    color: red; 
 }

 .admin-objet-contact .trash:hover{
    color: #c82333; 
 }
 