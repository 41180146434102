
@font-face {
  font-family: "Arial Black";
  src: url('./assets/fonts/arial_black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
}


@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --body-small-font-family: "DM Sans", Helvetica;
  --body-small-font-size: 16px;
  --body-small-font-style: normal;
  --body-small-font-weight: 400;
  --body-small-letter-spacing: 0.5px;
  --body-small-line-height: 24px;
  --card: 0px 0px 16px 0px rgba(64, 95, 88, 0.2);
  --coode-NT-desktop-body-bold-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-body-bold-font-size: 18px;
  --coode-NT-desktop-body-bold-font-style: normal;
  --coode-NT-desktop-body-bold-font-weight: 700;
  --coode-NT-desktop-body-bold-letter-spacing: 0px;
  --coode-NT-desktop-body-bold-line-height: normal;
  --coode-NT-desktop-body-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-body-font-size: 18px;
  --coode-NT-desktop-body-font-style: normal;
  --coode-NT-desktop-body-font-weight: 400;
  --coode-NT-desktop-body-letter-spacing: 0px;
  --coode-NT-desktop-body-line-height: normal;
  --coode-NT-desktop-body-italic-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-body-italic-font-size: 18px;
  --coode-NT-desktop-body-italic-font-style: italic;
  --coode-NT-desktop-body-italic-font-weight: 400;
  --coode-NT-desktop-body-italic-letter-spacing: 0px;
  --coode-NT-desktop-body-italic-line-height: normal;
  --coode-NT-desktop-body-small-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-body-small-font-size: 12px;
  --coode-NT-desktop-body-small-font-style: normal;
  --coode-NT-desktop-body-small-font-weight: 400;
  --coode-NT-desktop-body-small-letter-spacing: 0px;
  --coode-NT-desktop-body-small-line-height: normal;
  --coode-NT-desktop-h1-font-family: "Arial Black", Helvetica;
  --coode-NT-desktop-h1-font-size: 40px;
  --coode-NT-desktop-h1-font-style: normal;
  --coode-NT-desktop-h1-font-weight: 400;
  --coode-NT-desktop-h1-letter-spacing: 0px;
  --coode-NT-desktop-h1-line-height: normal;
  --coode-NT-desktop-h2-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-h2-font-size: 32px;
  --coode-NT-desktop-h2-font-style: normal;
  --coode-NT-desktop-h2-font-weight: 600;
  --coode-NT-desktop-h2-letter-spacing: 0px;
  --coode-NT-desktop-h2-line-height: normal;
  --coode-NT-desktop-h3-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-h3-font-size: 28px;
  --coode-NT-desktop-h3-font-style: normal;
  --coode-NT-desktop-h3-font-weight: 600;
  --coode-NT-desktop-h3-letter-spacing: 0px;
  --coode-NT-desktop-h3-line-height: normal;
  --coode-NT-desktop-h4-font-family: "Rubik", Helvetica;
  --coode-NT-desktop-h4-font-size: 24px;
  --coode-NT-desktop-h4-font-style: normal;
  --coode-NT-desktop-h4-font-weight: 600;
  --coode-NT-desktop-h4-letter-spacing: 0px;
  --coode-NT-desktop-h4-line-height: normal;
  --coode-NT-mobile-body-bold-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-body-bold-font-size: 16px;
  --coode-NT-mobile-body-bold-font-style: normal;
  --coode-NT-mobile-body-bold-font-weight: 700;
  --coode-NT-mobile-body-bold-letter-spacing: 0px;
  --coode-NT-mobile-body-bold-line-height: normal;
  --coode-NT-mobile-body-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-body-font-size: 16px;
  --coode-NT-mobile-body-font-style: normal;
  --coode-NT-mobile-body-font-weight: 400;
  --coode-NT-mobile-body-italic-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-body-italic-font-size: 16px;
  --coode-NT-mobile-body-italic-font-style: italic;
  --coode-NT-mobile-body-italic-font-weight: 400;
  --coode-NT-mobile-body-italic-letter-spacing: 0px;
  --coode-NT-mobile-body-italic-line-height: normal;
  --coode-NT-mobile-body-letter-spacing: 0px;
  --coode-NT-mobile-body-line-height: normal;
  --coode-NT-mobile-body-small-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-body-small-font-size: 12px;
  --coode-NT-mobile-body-small-font-style: normal;
  --coode-NT-mobile-body-small-font-weight: 400;
  --coode-NT-mobile-body-small-letter-spacing: 0px;
  --coode-NT-mobile-body-small-line-height: normal;
  --coode-NT-mobile-h1-font-family: "Arial Black", Helvetica;
  --coode-NT-mobile-h1-font-size: 32px;
  --coode-NT-mobile-h1-font-style: normal;
  --coode-NT-mobile-h1-font-weight: 400;
  --coode-NT-mobile-h1-letter-spacing: 0px;
  --coode-NT-mobile-h1-line-height: normal;
  --coode-NT-mobile-h2-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-h2-font-size: 28px;
  --coode-NT-mobile-h2-font-style: normal;
  --coode-NT-mobile-h2-font-weight: 600;
  --coode-NT-mobile-h2-letter-spacing: 0px;
  --coode-NT-mobile-h2-line-height: normal;
  --coode-NT-mobile-h3-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-h3-font-size: 24px;
  --coode-NT-mobile-h3-font-style: normal;
  --coode-NT-mobile-h3-font-weight: 600;
  --coode-NT-mobile-h3-letter-spacing: 0px;
  --coode-NT-mobile-h3-line-height: normal;
  --coode-NT-mobile-h4-font-family: "Rubik", Helvetica;
  --coode-NT-mobile-h4-font-size: 20px;
  --coode-NT-mobile-h4-font-style: normal;
  --coode-NT-mobile-h4-font-weight: 600;
  --coode-NT-mobile-h4-letter-spacing: 0px;
  --coode-NT-mobile-h4-line-height: normal;
  --domisimpli-desktop-body-bold-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-body-bold-font-size: 18px;
  --domisimpli-desktop-body-bold-font-style: normal;
  --domisimpli-desktop-body-bold-font-weight: 700;
  --domisimpli-desktop-body-bold-letter-spacing: 0px;
  --domisimpli-desktop-body-bold-line-height: normal;
  --domisimpli-desktop-body-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-body-font-size: 18px;
  --domisimpli-desktop-body-font-style: normal;
  --domisimpli-desktop-body-font-weight: 400;
  --domisimpli-desktop-body-letter-spacing: 0px;
  --domisimpli-desktop-body-line-height: 24px;
  --domisimpli-desktop-body-small-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-body-small-font-size: 14px;
  --domisimpli-desktop-body-small-font-style: normal;
  --domisimpli-desktop-body-small-font-weight: 400;
  --domisimpli-desktop-body-small-italic-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-body-small-italic-font-size: 16px;
  --domisimpli-desktop-body-small-italic-font-style: italic;
  --domisimpli-desktop-body-small-italic-font-weight: 400;
  --domisimpli-desktop-body-small-italic-letter-spacing: 0px;
  --domisimpli-desktop-body-small-italic-line-height: 24px;
  --domisimpli-desktop-body-small-letter-spacing: 0px;
  --domisimpli-desktop-body-small-line-height: 129.99999523162842%;
  --domisimpli-desktop-bouton-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-bouton-font-size: 20px;
  --domisimpli-desktop-bouton-font-style: normal;
  --domisimpli-desktop-bouton-font-weight: 700;
  --domisimpli-desktop-bouton-letter-spacing: 0px;
  --domisimpli-desktop-bouton-line-height: 32px;
  --domisimpli-desktop-h1-font-family: "Akshar", Helvetica;
  --domisimpli-desktop-h1-font-size: 40px;
  --domisimpli-desktop-h1-font-style: normal;
  --domisimpli-desktop-h1-font-weight: 600;
  --domisimpli-desktop-h1-letter-spacing: 0px;
  --domisimpli-desktop-h1-line-height: 48px;
  --domisimpli-desktop-h2-font-family: "Akshar", Helvetica;
  --domisimpli-desktop-h2-font-size: 32px;
  --domisimpli-desktop-h2-font-style: normal;
  --domisimpli-desktop-h2-font-weight: 600;
  --domisimpli-desktop-h2-letter-spacing: 0px;
  --domisimpli-desktop-h2-line-height: normal;
  --domisimpli-desktop-h3-font-family: "Akshar", Helvetica;
  --domisimpli-desktop-h3-font-size: 28px;
  --domisimpli-desktop-h3-font-style: normal;
  --domisimpli-desktop-h3-font-weight: 600;
  --domisimpli-desktop-h3-letter-spacing: 0px;
  --domisimpli-desktop-h3-line-height: normal;
  --domisimpli-desktop-h4-font-family: "Akshar", Helvetica;
  --domisimpli-desktop-h4-font-size: 24px;
  --domisimpli-desktop-h4-font-style: normal;
  --domisimpli-desktop-h4-font-weight: 600;
  --domisimpli-desktop-h4-letter-spacing: 0px;
  --domisimpli-desktop-h4-line-height: normal;
  --domisimpli-desktop-italic-font-family: "Roboto", Helvetica;
  --domisimpli-desktop-italic-font-size: 18px;
  --domisimpli-desktop-italic-font-style: italic;
  --domisimpli-desktop-italic-font-weight: 400;
  --domisimpli-desktop-italic-letter-spacing: 0px;
  --domisimpli-desktop-italic-line-height: normal;
  --domisimpli-desktop-minihead-font-family: "Akshar", Helvetica;
  --domisimpli-desktop-minihead-font-size: 20px;
  --domisimpli-desktop-minihead-font-style: normal;
  --domisimpli-desktop-minihead-font-weight: 500;
  --domisimpli-desktop-minihead-letter-spacing: 0px;
  --domisimpli-desktop-minihead-line-height: normal;
  --domisimpli-mobile-body-bold-font-family: "Roboto", Helvetica;
  --domisimpli-mobile-body-bold-font-size: 16px;
  --domisimpli-mobile-body-bold-font-style: normal;
  --domisimpli-mobile-body-bold-font-weight: 700;
  --domisimpli-mobile-body-bold-letter-spacing: 0px;
  --domisimpli-mobile-body-bold-line-height: 24px;
  --domisimpli-mobile-body-font-family: "Roboto", Helvetica;
  --domisimpli-mobile-body-font-size: 16px;
  --domisimpli-mobile-body-font-style: normal;
  --domisimpli-mobile-body-font-weight: 400;
  --domisimpli-mobile-body-italic-font-family: "Roboto", Helvetica;
  --domisimpli-mobile-body-italic-font-size: 16px;
  --domisimpli-mobile-body-italic-font-style: italic;
  --domisimpli-mobile-body-italic-font-weight: 400;
  --domisimpli-mobile-body-italic-letter-spacing: 0px;
  --domisimpli-mobile-body-italic-line-height: 24px;
  --domisimpli-mobile-body-letter-spacing: 0px;
  --domisimpli-mobile-body-line-height: 24px;
  --domisimpli-mobile-body-small-font-family: "Roboto", Helvetica;
  --domisimpli-mobile-body-small-font-size: 12px;
  --domisimpli-mobile-body-small-font-style: normal;
  --domisimpli-mobile-body-small-font-weight: 400;
  --domisimpli-mobile-body-small-letter-spacing: 0px;
  --domisimpli-mobile-body-small-line-height: 16px;
  --domisimpli-mobile-bouton-font-family: "Roboto", Helvetica;
  --domisimpli-mobile-bouton-font-size: 18px;
  --domisimpli-mobile-bouton-font-style: normal;
  --domisimpli-mobile-bouton-font-weight: 700;
  --domisimpli-mobile-bouton-letter-spacing: 0px;
  --domisimpli-mobile-bouton-line-height: 32px;
  --domisimpli-mobile-h1-font-family: "Akshar", Helvetica;
  --domisimpli-mobile-h1-font-size: 28px;
  --domisimpli-mobile-h1-font-style: normal;
  --domisimpli-mobile-h1-font-weight: 400;
  --domisimpli-mobile-h1-letter-spacing: 0px;
  --domisimpli-mobile-h1-line-height: 40px;
  --domisimpli-mobile-h2-font-family: "Akshar", Helvetica;
  --domisimpli-mobile-h2-font-size: 24px;
  --domisimpli-mobile-h2-font-style: normal;
  --domisimpli-mobile-h2-font-weight: 400;
  --domisimpli-mobile-h2-letter-spacing: 0px;
  --domisimpli-mobile-h2-line-height: 40px;
  --domisimpli-mobile-h3-font-family: "Akshar", Helvetica;
  --domisimpli-mobile-h3-font-size: 20px;
  --domisimpli-mobile-h3-font-style: normal;
  --domisimpli-mobile-h3-font-weight: 400;
  --domisimpli-mobile-h3-letter-spacing: 0px;
  --domisimpli-mobile-h3-line-height: 32px;
  --domisimpli-mobile-h4-font-family: "Akshar", Helvetica;
  --domisimpli-mobile-h4-font-size: 18px;
  --domisimpli-mobile-h4-font-style: normal;
  --domisimpli-mobile-h4-font-weight: 400;
  --domisimpli-mobile-h4-letter-spacing: 0px;
  --domisimpli-mobile-h4-line-height: 24px;
  --domisimpli-mobile-minihead-font-family: "Akshar", Helvetica;
  --domisimpli-mobile-minihead-font-size: 16px;
  --domisimpli-mobile-minihead-font-style: normal;
  --domisimpli-mobile-minihead-font-weight: 600;
  --domisimpli-mobile-minihead-letter-spacing: 0px;
  --domisimpli-mobile-minihead-line-height: 24px;
  --domisimplivertvert: rgba(91, 135, 113, 1);
  --domisimplivertvert-clair: rgba(208, 228, 223, 1);
  --domisimplivertvert-dark: rgba(12, 34, 29, 1);
  --domisimplivertvert-gris: rgba(246, 255, 250, 1);
  --domisimplivertvert-sapin: rgba(50, 86, 78, 1);
  --neutral-0: rgba(255, 255, 255, 1);
  --neutral-10: rgba(72, 98, 132, 1);
  --neutral-7: rgba(140, 162, 192, 1);
  --ombre: 0px 4px 28px 0px rgba(48, 84, 76, 1);
  --ombre-verte: 0px -4px 8px 0px rgba(64, 95, 88, 0.1);
  --purple: 0px 0px 16px 0px rgba(62, 0, 101, 0.2);
  --coode-NT-blue : #170e91;
  --coode-NT-vert : #25e89f;
  --coode-NT-blanc : #fcfcfa;
  --coode-NT-violet : #655ec91a;
  --coode-NT-violet-clair : #655ec9;
}

.margin-zero {
  margin: 0;
}


h2 {
  text-align: center;
  margin: 0 0 20px;
}


label {
  font-weight: bold;
}

/* input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;

  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
} */



@media (max-width: 768px) {
  .candidature-container {
      padding: 10px;
  }

  /* input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select,
  input[type="file"] {
      font-size: 16px;
  } */
}