.administration {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: flex-start;

    justify-content: flex-start;
    min-height: 80vh;
    padding: 1%;
    background-color: #f9f9f9;
    color: #333;
}

.tabs {
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    border: none;
    -webkit-border-radius: 10px;

    border-radius: 10px;
    padding: 10px;
    gap: 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.tab-content {
    padding: 30px;
    border: 1px solid #ddd;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 1200px;
}

/* Boutons des onglets */
.bar-normal, .bar-active {
    padding: 12px 25px;
    -webkit-border-radius: 30px;

    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9rem;
    border: 2px solid transparent;
    -webkit-transition: background-color 0.3s, color 0.3s, border-color 0.3s;

    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.bar-normal {
    background-color: #f0f0f0;
    color: #333;
}

.bar-normal:hover {
    background-color: #e0e0e0;
}

.bar-active {
    background-color: #170e91;
    color: #fff;
}

.bar-active:hover {
    background-color: #2820d1;
}

/* Sous-onglets */
.subbar-active {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    -webkit-border-radius: 30px;

    border-radius: 30px;
    border-color: #28a745;
}

.subbar-active:hover {
    background-color: #218838;
    border-color: #218838;
}

.liste-info {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 10px;
    width: 60vw;
    -webkit-justify-content: flex-start;

    justify-content: flex-start;
    -webkit-justify-content: center;

    justify-content: center;
}

.administration .admin-form {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    width: 60vw;
    -webkit-align-items: center;

    align-items: center;
    margin: auto;
    padding: 20px;
    gap: 15px;
    /* Espacement entre les éléments du formulaire */
}

.affichage-data {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    width: 25vw;
}

.administration .admin-form label,
.administration .admin-form input,
.administration .admin-form textarea {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
    /* Inclut padding dans la largeur */
}

.administration .admin-form textarea {
    height: 35vh;
    /* Hauteur fixe pour le textarea */
    resize: vertical;
    /* Permet à l'utilisateur de redimensionner verticalement */
}

.affichage-video {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    width: 21vw;
}

.info-item {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
}

.info-add-button {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    height: 40px;
    cursor: pointer;
}

.info-add-affichageForm {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    height: 40px;
    width: 50vw;
    cursor: pointer;
}

.info-item p {
    margin: 0;
}

.display-checkbox {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
}

.faq-admin-item-content {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    width: 75%;
}

.edit-button {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    background-color: #007bff;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 100%;
    height: 40px;
    color: white;
}

.delete-button {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    background-color: #dc3545;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 100%;
    height: 40px;
    margin-top: 5px;
    color: white;
}

.delete-button:hover {
    background-color: #b02a37;
}   

.add-button {
    background-color: #11ce00;
    -webkit-align-items: center;

    align-items: center;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 15%;
    height: 40px;
    color: white;
}

.add-button:hover {
    background-color: #0f9d00;
}

.cancel-button {
    background-color: #dc3545;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 15%;
    height: 40px;
    color: white;
}

.cancel-button:hover {
    background-color: #b02a37;
}

/* Responsive design */
@media (max-width: 768px) {
    .administration .admin-form {
        padding: 10px;
    }
}

.newsletter-container {
    width: 60vw;
    height: 60vh;
    margin: 0 auto;
    padding: 20px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px;

    border-radius: 8px;
    background-color: #fff;
}

.newsletter-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.email-content {
    width: 100%;
    height: 40vh;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    font-size: 16px;
    resize: none;
}

.send-options {
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.send-date {
    padding: 10px;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.send-now,
.schedule-send {
    padding: 10px 20px;
    border: none;
    -webkit-border-radius: 4px;

    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

.send-now {
    background-color: #007bff;
}

.schedule-send {
    background-color: #28a745;
}

@media (max-width: 768px) {
    .send-options {
        -webkit-flex-direction: column;

        flex-direction: column;
    }
}

/* Réutilisation des styles de formulaire pour DomiSimpli */
.domisimpli-container .admin-form {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    width: 60vw;
    -webkit-align-items: center;

    align-items: center;
    margin: auto;
    padding: 20px;
    gap: 15px;
}

.domisimpli-container .admin-form input,
.domisimpli-container .admin-form textarea {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;

    box-sizing: border-box;
}

.domisimpli-container .admin-form textarea {
    height: 20vh;
    /* Ajustement pour le texte spécifique à DomiSimpli */
    resize: vertical;
}

/* Styles spécifiques pour la liste des publicités */
.publicites-list {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 10px;
    width: 60vw;
    margin-top: 20px;
}

.publicite-item {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
}

/* Styles pour les boutons d'ajout et d'annulation */
.publicites-actions {
    display: flex;
    -webkit-justify-content: center;

    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.email-subject {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    margin-bottom: 10px;
}

.add-publicite-button {
    background-color: #28a745;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 25%;
    height: 40px;
    color: white;
}

.add-publicite-button:hover {
    background-color: #218838;
}

.cancel-publicite-button {
    background-color: #dc3545;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 25%;
    height: 40px;
    color: white;
}

.cancel-publicite-button:hover {
    background-color: #b02a37;
}

/* Styles généraux pour la section d'administration */
.faq-admin-container {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
}

/* Style pour chaque item de question */
.faq-admin-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    margin: 1%;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 60vw;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    -webkit-justify-content: space-between;

    justify-content: space-between;
    -webkit-align-items: center;

    align-items: center;
}

/* Style pour les questions sans réponse */
.no-answer {
    background-color: #ffecec;
    border-color: #ffcccc;
}

/* Style pour le formulaire de réponse */
.form-reponse {
    background-color: #fff;
    padding: 20px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.form-reponse input,
.form-reponse textarea {
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    width: 100%;
}

/* Bouton pour fermer le formulaire */
.close-form-btn {
    align-self: flex-end;
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
}

.response-textarea {
    height: 20vh;
    resize: vertical;
    margin-top: 1%;
}

.faq-admin-item-buttons {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
}

.respond-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
}

.admin-delete-button {
    padding: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
}

.admin-delete-button:hover {
    background-color: #b02a37;
}

/* Met en avant la question concernée */
.question-highlighted {
    border-color: #007bff;
    background-color: #e7f3ff;
}

/* Responsive design */
@media (max-width: 768px) {
    .faq-admin-container {
        width: 90%;
        padding: 10px;
    }

    .form-reponse {
        width: 90%;
    }
}

.text-editor {
    max-height: 400px;
    /* Ajustez selon vos besoins */
    overflow: auto;
    /* Permet le défilement si le contenu dépasse la hauteur maximale */
    border: 1px solid #ccc;
    /* Optionnel: ajoute une bordure autour de l'éditeur */
    padding: 10px;
    /* Optionnel: ajoute un peu d'espace à l'intérieur de l'éditeur */
    margin-bottom: 20px;
    /* Optionnel: ajoute de l'espace en dessous de l'éditeur */
}

/* Assurer la responsivité sur les petits écrans */
@media (max-width: 768px) {

    .domisimpli-container .admin-form,
    .publicites-list {
        width: 90vw;
        /* Ajustement pour les petits écrans */
    }
}

.contact-list-container {
    margin-top: 20px;
}

.contact-list-title {
    text-align: center;
    margin-bottom: 30px;
}

.contact-list {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.contact-item {
    background-color: #f9f9f9;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    padding: 20px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-info p {
    margin: 5px 0;
}

.contact-subject {
    font-weight: bold;
    font-size: 1.5em;
}

.contact-date {
    font-size: 1.2em;
}

.contact-name {
    font-weight: bold;
}

.contact-actions {
    text-align: right;
    margin-top: 20px;
}

.admin-delete-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;

    transition: background-color 0.3s ease;
}

.admin-mark-as-responded-button {
    background-color: #40ad0a;
    color: white;
    border: none;
    padding: 10px 20px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;

    transition: background-color 0.3s ease;
    margin-inline: 5px;
}

.admin-reply-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;

    transition: background-color 0.3s ease;
}

.admin-mark-as-responded-button:hover {
    background-color: #3a9e08;
}

.admin-delete-button:hover {
    background-color: #ff7875;
}

.contact-badge {
    color: #ff7875;
    padding: 5px 10px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    border: 1px solid #ff7875;
    margin-inline-start: 5px;
}

.no-contacts {
    text-align: center;
}

@media (max-width: 768px) {
    .contact-actions {
        text-align: center;
    }
}

.administration-coodent {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 60vw;
}

.administration-collaborateurs {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
}

.collaborateur-cards-container {
    display: flex;
    flex-wrap: wrap;
    -webkit-justify-content: center;

    justify-content: center;
    gap: 20px;
}

.collaborateur-card {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    padding: 20px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-height: 35vh;

}

.administration-partenaires{
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
}

.partenaires-cards-container {
    display: flex;
    flex-wrap: wrap;
    -webkit-justify-content: center;

    justify-content: center;
    gap: 20px;
}

.partenaires-card{
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    margin-block: 20px;
    padding: 20px;
    margin-inline: 200px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 15vh;
    width: 35vw;
}

.partenaires-image {
    max-width: 100px;
    margin-top: 10px;
    -webkit-border-radius: 4px;

    border-radius: 4px;
}


.administration-projets{
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
}

.projets-cards-container {
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
}

.projets-card{
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    margin-block: 20px;
    padding: 20px;
    margin-inline: 0px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 20vh;
    width: 35vw;
}

.projets-image {
    max-width: 100px;
    margin-top: 10px;
    -webkit-border-radius: 4px;

    border-radius: 4px;
}

.administration-valeurs{
    display: flex;
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
}

.valeurs-cards-container {
    display: flex;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
}

.admin-valeur-card{
    display: flex;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    margin-block: 20px;
    padding: 20px;
    margin-inline: 200px;
    -webkit-border-radius: 8px;

    border-radius: 8px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 10vh;
    width: 20vw;
}

.valeur-image {
    max-width: 100px;
    margin-top: 10px;
    -webkit-border-radius: 4px;

    border-radius: 4px;
}

.selected {
    border: 2px solid #3e8de2;
}

.pdfButton {
    color: rgb(255, 0, 0);
    border: none;
    padding: 10px 20px;
    -webkit-border-radius: 5px;

    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;

    transition: background-color 0.3s ease;
    width: 50px;
}

.valeur-cards-container {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;

    align-items: center;
    -webkit-justify-content: center;

    justify-content: center;
    gap: 20px;
}