.frame {
  -webkit-align-items: center;

  align-items: center;
  background-color: #170e91;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  padding: 80px 193px 24px;
  position: relative;
}

.frame .div-2 {
  -webkit-align-items: flex-start;

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-justify-content: space-between;

  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 96px;
}

.frame .div-3 {
  -webkit-align-items: center;

  align-items: center;
  display: flex;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 218px;
}

.frame .coode-nt-white {
  height: 35.39px;
  object-fit: cover;
  position: relative;
  width: 218px;
}

.frame .text-wrapper-2 {
  align-self: stretch;
  color: #fcfcfa;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  text-align: center;
  text-decoration: none;
}

.frame .design-component-instance-node {
  border-color: #fcfcfa !important;
  -webkit-flex: 0 0 auto !important;

  flex: 0 0 auto !important;
}

.frame .button-2 {
  color: #fcfcfa !important;
}

.frame .div-4 {
  -webkit-align-items: center;

  align-items: center;
  display: inline-flex;
  -webkit-flex: 0 0 auto;

  flex: 0 0 auto;
  -webkit-flex-direction: column;

  flex-direction: column;
  gap: 48px;
  position: relative;
}

.frame .text-wrapper-3 {
  color: #fcfcfa;
  font-family: var(--coode-NT-desktop-h4-font-family);
  font-size: var(--coode-NT-desktop-h4-font-size);
  font-style: var(--coode-NT-desktop-h4-font-style);
  font-weight: var(--coode-NT-desktop-h4-font-weight);
  letter-spacing: var(--coode-NT-desktop-h4-letter-spacing);
  line-height: var(--coode-NT-desktop-h4-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-decoration: none;
}

.frame .text-wrapper-4 {
  color: #fcfcfa;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  text-decoration: none;
}

.frame .text-wrapper-5 {
  color: #fcfcfa;
  font-family: var(--coode-NT-desktop-body-font-family);
  font-size: var(--coode-NT-desktop-body-small-font-size);
  font-style: var(--coode-NT-desktop-body-font-style);
  font-weight: var(--coode-NT-desktop-body-font-weight);
  letter-spacing: var(--coode-NT-desktop-body-letter-spacing);
  line-height: var(--coode-NT-desktop-body-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  gap: 16px;
}

.frame .text-wrapper-5 a {
  color: #fcfcfa;
  ;
}


/* Footer Styles for Mobile */
@media only screen and (max-width: 768px) {
  .frame {
    padding: 56px 24px 56px;
    gap: 0px;
  }

  .frame .div-2 {
    -webkit-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;

    align-items: center;
    margin-bottom: 0;
  }

  .frame .div-3,
  .frame .div-4 {
    width: 100%;
    -webkit-align-items: center;

    align-items: center;
    gap: 24px;
    margin-top: 0;
    margin-bottom: 80px;
    padding: 0;
  }

  .frame .text-wrapper-3 {
    font-family: var(--coode-NT-mobile-h4-font-family);
    font-size: var(--coode-NT-mobile-h4-font-size);
    font-style: var(--coode-NT-mobile-h4-font-style);
    font-weight: var(--coode-NT-mobile-h4-font-weight);
    letter-spacing: var(--coode-NT-mobile-h4-letter-spacing);
    line-height: var(--coode-NT-mobile-h4-line-height);
    text-align: center;
    margin: 0;
  }

  .frame .text-wrapper-2,
  .frame .text-wrapper-4 {
    font-size: var(--coode-NT-mobile-body-font-size);
    text-align: center;
    margin: 0 0 16px;
  }

  .frame .coode-nt-white {
    height: 30px;
    width: auto;
  }

  .frame .ellipse {
    height: 40px;
    width: 40px;
  }
}